import React, { Component } from 'react';
import styled from '@emotion/styled';
import { STATUS_COLOR_MAPPING } from '../common/utils';
import StatusExample from './StatusExample';

const Container = styled.div`
    display: flex;
    justify-content: center;
`;

class StatusExamples extends Component {
    render() {
        return (
            <Container>
                {' '}
                {Object.keys(STATUS_COLOR_MAPPING).map((key, index) => {
                    return (
                        <StatusExample
                            key={index}
                            color={STATUS_COLOR_MAPPING[key].color}
                            text={STATUS_COLOR_MAPPING[key].label}
                        />
                    );
                })}{' '}
            </Container>
        );
    }
}

export default StatusExamples;
