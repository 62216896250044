import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import React, { Component } from 'react';
import { d3DagNode, GraphSettings, FailureDetail } from './Types';

/**
 * Similar to Links this component is actually all of the nodes in one component.
 * There are ways of singular components per node, but I think that is actually harder to
 * understand. Since it makes the d3 setup logic more awkward.
 */
class Nodes extends Component<Props> {
    ref!: SVGGElement;

    renderNodes() {        
        const borderColorMap = this.props.settings.borderColorMap ?? new Map();
        const errors: Map<string, FailureDetail> = this.props.settings.errors ?? new Map();

        const context = d3.select(this.ref);
        const startX = this.props.settings.startX;
        const startY = this.props.settings.startY;
        // Tooltips shown on hover
        // The types here seem a bit broken. So I'm just using any here.
        // Lots of issues online trying to get this imported with types, doesn't seem to have a consistent solution
        const tip = (d3Tip as any)()
            .attr('class', 'd3-tip')
            .offset([-10, 0])
            .html((node: any) => {
                const error = errors.get(node.data.id)
                if (error) {
                    const message =
                        this.isGroupNode(node.data.id) ?
                        error.reason :
                        'Click node to view full error details';
                    return (`${node.data.id}<br/><br/> 
                            Status: ${error.failureType}                          
                            <p class="d3-tip-instruction">${message}</p>`)
                } else {
                    return node.data.id
                }
            });
        context.call(tip);
        // Parent node object
        const nodes = context
            .append('g')
            .selectAll('g')
            .data(this.props.nodes)
            .enter()
            .append('g')
            .attr('transform', ({ x, y }) => `translate(${startX + x}, ${startY + y})`)
            // ActivityId is unique, so this is fine to use as the id
            .attr('id', (node) => node.data.id)
            .style('cursor', (node) => this.isGroupEndNode(node.data.id) ? 'default' : 'pointer')
            .on('mouseover', tip.show)
            .on('mouseout', tip.hide)
            .on('click', (e) => {
                tip.hide(e)
                this.nodeClicked(e)
            })
        // Plot circles
        nodes
            .append('circle')
            .attr('id', (node) => node.data.id)
            .attr('r', this.props.settings.nodeRadius)
            .attr('fill', (node) => this.props.settings.colorMap.get(node.data.id)!)
            .attr('stroke', (node) => borderColorMap.get(node.data.id))
            .attr('stroke-width', 3)
            .attr('opacity', (node) =>
                this.isGroupNode(node.data.id) ? 0.5 : 1.0,
            );

        // Text
        nodes
            .append('text')
            .text((node) => node.data.id.substring(0, 6))
            .attr('font-weight', 'bold')
            .attr('font-family', 'sans-serif')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .attr('font-size', '12px')
            .attr('fill', 'white');
    }

    componentDidUpdate() {
        d3.select(this.ref).selectAll('*').remove()
        this.renderNodes();
    }

    componentDidMount() {
        this.renderNodes();
    }

    nodeClicked = (node: any) => {        
        this.props.onNodeClicked(node.data)
    }

    render() {
        return <g className="nodes" ref={(ref: SVGGElement) => (this.ref = ref)} />;
    }

    isGroupNode = (id: string): boolean => {
        return id.startsWith('START[') || id.startsWith('END[') || id.startsWith('GROUP[');
    }

    isGroupEndNode = (id: string): boolean => {
        return id.startsWith('END[');
    }
}

type Props = {
    settings: GraphSettings;    
    nodes: d3DagNode[];    
    onNodeClicked: Function;
};

export default Nodes;