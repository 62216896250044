import React, { Component } from 'react';
import '../style/pikachu.css';

// Source: https://codepen.io/mikibrei/pen/JRRwEj

class LoadAChu extends Component {
    render() {
        return (
            <div className={'pikachu-wrapper'}>
                <svg
                    version={'1.1'}
                    xmlns={'http://www.w3.org/2000/svg'}
                    x={'0px'}
                    y={'0px'}
                    viewBox={'0 0 800 600'}
                    style={{ background: 'new 0 0 800 600' }}
                    xmlSpace="preserve"
                >
                    <g id="cola">
                        <g id="Capa_33">
                            <path
                                className="st1"
                                d="M449,216.9H420l-16,28h13l-17,34.5l44.7-40h-14.5L449,216.9z M449,216.9"
                            />
                        </g>
                        <g id="Capa_32">
                            <path
                                className="st2"
                                d="M414.5,226.6l-10.4,18.3h13l-17,34.5l33.8-30.3h-11.3l8.1-9.7h-0.5l10.7-12.9H414.5z M414.5,226.6"
                            />
                        </g>
                        <g id="Capa_31">
                            <path
                                className="st3"
                                d="M400,281.1c-0.4,0-0.7-0.1-1-0.3c-0.6-0.5-0.8-1.3-0.5-2.1l15.8-32.1h-10.3c-0.6,0-1.1-0.3-1.4-0.8
                                c-0.3-0.5-0.3-1.1,0-1.7l15.9-28c0.3-0.5,0.9-0.8,1.5-0.8H449c0.7,0,1.2,0.4,1.5,1c0.3,0.6,0.2,1.3-0.2,1.8l-16.5,19.8h11
                                c0.7,0,1.3,0.4,1.6,1.1c0.2,0.6,0.1,1.4-0.4,1.8l-44.7,40C400.8,280.9,400.4,281.1,400,281.1L400,281.1z M407,243.2H417
                                c0.6,0,1.1,0.3,1.4,0.8c0.3,0.5,0.3,1.1,0.1,1.6l-13.2,26.8l35-31.3h-10.1c-0.6,0-1.2-0.4-1.5-1c-0.3-0.6-0.2-1.3,0.2-1.8
                                l16.5-19.8H421L407,243.2z M407,243.2"
                            />
                        </g>
                    </g>

                    <g id="cuerpo">
                        <g>
                            <path
                                className="st4"
                                d="M473.9,304.3c0,23-24.5,41.7-54.8,41.7c-30.3,0-54.8-18.7-54.8-41.7c0-23,24.5-50.6,54.8-50.6
                                C449.4,253.6,473.9,281.2,473.9,304.3L473.9,304.3z M473.9,304.3"
                            />
                            <path
                                className="st1"
                                d="M473.9,304.3c0,23-24.5,41.7-54.8,41.7c-30.3,0-54.8-18.7-54.8-41.7c0-23,24.5-38.9,54.8-38.9
                                C449.4,265.4,473.9,281.2,473.9,304.3L473.9,304.3z M473.9,304.3"
                            />
                            <path
                                className="st2"
                                d="M473.9,304.3c0,23-24.5,41.7-54.8,41.7c-30.3,0-54.8-18.7-54.8-41.7c0-23,7,20.4,54.8,20.4
                                C467,324.7,473.9,281.2,473.9,304.3L473.9,304.3z M473.9,304.3"
                            />
                        </g>
                    </g>

                    <g id="oreja-izq">
                        <g>
                            <path
                                className="st1"
                                d="M358.2,262.9c7.1,11.7,17.1,18.6,26.7,19.8c1.7-12-1-25.9-8.1-37.6c-7.1-11.7-17.1-18.6-26.7-19.8
                                C348.5,237.3,351.1,251.2,358.2,262.9L358.2,262.9z M358.2,262.9"
                            />
                            <path
                                className="st2"
                                d="M350.2,225.3c-1.7,12,1,25.9,8.1,37.6c7.1,11.7,17.1,18.6,26.7,19.8L350.2,225.3z M350.2,225.3"
                            />
                        </g>
                    </g>
                    <g id="oreja-der">
                        <g>
                            <path
                                className="st1"
                                d="M480,262.9c-7.1,11.7-17.1,18.6-26.7,19.8c-1.7-12,1-25.9,8.1-37.6c7.1-11.7,17.1-18.6,26.7-19.8
                                C489.8,237.3,487.2,251.2,480,262.9L480,262.9z M480,262.9"
                            />
                            <path
                                className="st2"
                                d="M488.1,225.3c1.7,12-1,25.9-8.1,37.6c-7.1,11.7-17.1,18.6-26.7,19.8L488.1,225.3z M488.1,225.3"
                            />
                        </g>
                    </g>

                    <g id="cachete-rojo-izq">
                        <g>
                            <path
                                className="st5"
                                d="M392.4,308c0,4.3-3.5,7.8-7.8,7.8c-4.3,0-7.8-3.5-7.8-7.8c0-4.3,3.5-7.8,7.8-7.8
                                C388.9,300.2,392.4,303.7,392.4,308L392.4,308z M392.4,308"
                            />
                            <path
                                className="st6"
                                d="M392.4,308c0,4.3-3.5,7.8-7.8,7.8c-4.3,0-7.8-3.5-7.8-7.8"
                            />
                        </g>
                    </g>

                    <g id="cachete-rojo-der">
                        <g>
                            <path
                                className="st5"
                                d="M461.5,308c0,4.3-3.5,7.8-7.8,7.8c-4.3,0-7.8-3.5-7.8-7.8c0-4.3,3.5-7.8,7.8-7.8
                                C458,300.2,461.5,303.7,461.5,308L461.5,308z M461.5,308"
                            />
                            <path
                                className="st6"
                                d="M461.5,308c0,4.3-3.5,7.8-7.8,7.8c-4.3,0-7.8-3.5-7.8-7.8"
                            />
                        </g>
                    </g>
                    <g id="nariz">
                        <path
                            className="st3"
                            d="M429.9,299.7c-0.9,0-1.7,0.8-1.7,1.7c0,1.6-1.3,2.9-2.9,2.9h-1.6c-1.6,0-2.9-1.3-2.9-2.9v-1.6
                            c0-0.1,0-0.2,0-0.2l3.5-1.7c0.7-0.3,1.1-1.1,0.9-1.9c-0.2-0.8-0.8-1.3-1.6-1.3h-8.7c-0.8,0-1.4,0.5-1.6,1.3
                            c-0.2,0.8,0.2,1.5,0.9,1.9l3.5,1.7c0,0.1,0,0.2,0,0.2v1.6c0,1.6-1.3,2.9-2.9,2.9H413c-1.6,0-2.9-1.3-2.9-2.9c0-0.9-0.8-1.7-1.7-1.7
                            c-0.9,0-1.7,0.8-1.7,1.7c0,3.4,2.8,6.2,6.2,6.2h1.6c1.8,0,3.4-0.8,4.6-2c1.1,1.2,2.8,2,4.6,2h1.6c3.4,0,6.2-2.8,6.2-6.2
                            C431.5,300.4,430.8,299.7,429.9,299.7L429.9,299.7z M429.9,299.7"
                        />
                    </g>
                    <g id="Capa_14">
                        <path
                            className="st7"
                            d="M401.1,288.4c0,5-4,9-9,9c-5,0-9-4-9-9c0-5,4-9,9-9C397.1,279.4,401.1,283.4,401.1,288.4L401.1,288.4z
                            M401.1,288.4"
                        />
                    </g>
                    <g id="Capa_13">
                        <path
                            className="st8"
                            d="M391.4,285.5c0,2-1.6,3.7-3.7,3.7c-2,0-3.7-1.6-3.7-3.7c0-2,1.6-3.7,3.7-3.7
                            C389.7,281.8,391.4,283.4,391.4,285.5L391.4,285.5z M391.4,285.5"
                        />
                    </g>
                    <g id="Capa_12">
                        <path
                            className="st3"
                            d="M392.2,277.7c-3.6,0-6.8,1.8-8.7,4.6c-0.1,0.2-0.2,0.3-0.3,0.5c-1,1.6-1.6,3.5-1.6,5.6
                            c0,5.9,4.8,10.6,10.6,10.6s10.6-4.8,10.6-10.6S398,277.7,392.2,277.7L392.2,277.7z M392.2,295.7c-3,0-5.6-1.9-6.7-4.5
                            c0.8,0,1.7-0.2,2.7-0.4c0.4,0,0.8-0.1,1.1-0.2c0.9-0.2,1.9-0.3,2.9-0.3c2.7,0,5.1,0.9,6.7,1C397.8,293.8,395.2,295.7,392.2,295.7
                            L392.2,295.7z M392.2,295.7"
                        />
                    </g>
                    <g id="iris-izquierdo">
                        <path
                            className="st8"
                            d="M389.7,285.5c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2s0.9-2,2-2C388.8,283.5,389.7,284.4,389.7,285.5L389.7,285.5z
                            M389.7,285.5"
                        />
                    </g>
                    <g id="Capa_10">
                        <path
                            className="st7"
                            d="M455.1,288.4c0,5-4,9-9,9c-5,0-9-4-9-9c0-5,4-9,9-9C451.1,279.4,455.1,283.4,455.1,288.4L455.1,288.4z
                            M455.1,288.4"
                        />
                    </g>
                    <g id="Capa_9">
                        <path
                            className="st8"
                            d="M445.3,285.5c0,2-1.6,3.7-3.6,3.7c-2,0-3.7-1.6-3.7-3.7c0-2,1.6-3.7,3.7-3.7
                            C443.7,281.8,445.3,283.4,445.3,285.5L445.3,285.5z M445.3,285.5"
                        />
                    </g>
                    <g id="Capa_8">
                        <path
                            className="st3"
                            d="M446.1,277.7c-3.6,0-6.8,1.8-8.7,4.6c-0.1,0.2-0.2,0.3-0.3,0.5c-1,1.6-1.6,3.5-1.6,5.6
                            c0,5.9,4.8,10.6,10.6,10.6c5.9,0,10.6-4.8,10.6-10.6S452,277.7,446.1,277.7L446.1,277.7z M446.1,295.7c-3,0-5.6-1.9-6.7-4.5
                            c0.8,0,1.7-0.2,2.8-0.4c0.4,0,0.7-0.1,1.1-0.2c0.9-0.2,1.9-0.3,2.9-0.3c2.7,0,5.1,0.9,6.7,1C451.7,293.8,449.1,295.7,446.1,295.7
                            L446.1,295.7z M446.1,295.7"
                        />
                    </g>
                    <g id="iris-derecho">
                        <path
                            className="st8"
                            d="M443.6,285.5c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S443.6,284.4,443.6,285.5L443.6,285.5z M443.6,285.5"
                        />
                    </g>
                    <g id="rayos">
                        <g>
                            <g>
                                <path
                                    className="st9"
                                    d="M488.8,167.6c-6.6,6.6-13,16.3-17.8,24.3c2.2,0.2,7.2,0.7,9.5,0.8c-2.5,6.2-6.4,12.8-7.6,19.5
                                    c6.5-7.5,13.5-17.8,17.9-26.6c-2.2-0.2-4.8-0.4-7.1-0.5C483.6,185,488.2,170.1,488.8,167.6z"
                                />
                            </g>
                            <g>
                                <path
                                    className="st91"
                                    d="M384.1,160.2c-8,8-15.7,19.7-21.5,29.4c2.7,0.3,8.7,0.9,11.5,1c-3.1,7.5-7.8,15.5-9.2,23.5
                                    c7.9-9.1,16.3-21.6,21.6-32.2c-2.7-0.2-5.8-0.5-8.5-0.5C377.9,181.3,383.4,163.3,384.1,160.2z"
                                />
                            </g>
                            <g>
                                <path
                                    className="st11"
                                    d="M399.7,194.7c-3.8,3.9-7.6,9.5-10.3,14.1c1.3,0.1,4.2,0.4,5.5,0.5c-1.5,3.6-3.7,7.4-4.4,11.3
                                    c3.8-4.3,7.8-10.4,10.4-15.5c-1.3-0.1-2.8-0.2-4.1-0.3C396.8,204.8,399.4,196.2,399.7,194.7z"
                                />
                            </g>
                            <g>
                                <path
                                    className="st2"
                                    d="M469.8,136.8c-3.8,3.9-7.6,9.5-10.3,14.1c1.3,0.1,4.2,0.4,5.5,0.5c-1.5,3.6-3.7,7.4-4.4,11.3
                                    c3.8-4.3,7.8-10.4,10.4-15.5c-1.3-0.1-2.8-0.2-4.1-0.3C466.8,147,469.5,138.3,469.8,136.8z"
                                />
                            </g>
                            <g>
                                <path
                                    className="st1"
                                    d="M439.4,140.5c-8,8.1-15.9,19.9-21.6,29.6c2.7,0.3,8.8,0.9,11.6,1c-3.1,7.6-7.8,15.6-9.3,23.7
                                    c7.9-9.1,16.4-21.7,21.8-32.4c-2.7-0.2-5.9-0.5-8.6-0.6C433.2,161.7,438.7,143.6,439.4,140.5z"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}

export default LoadAChu;
