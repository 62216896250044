import React, { Component} from "react";

import {
    SelectProps,
} from './types';
import {Label, PaddedDiv} from './Forms.styles';
import Select from '@atlaskit/select';
import Button from '@atlaskit/button';
 import styled from '@emotion/styled';
import Banner from '@atlaskit/banner';


const Container = styled.div`
    width: 100%;
    padding: 12px 0;
`

const BannerContainer = styled.div`
    width: 100%;
    padding: 2px;
`

export interface DEPInputFormProps {
    generateStructuralDag: (provisionableType: string | undefined, provisionableVersion: string | undefined) => void;
    provisionableType: SelectProps;
    provisionableVersion: SelectProps;
    diffsInDag?: any;
    onSubmit?: () => void;
 }


class DEPInputForm extends Component<DEPInputFormProps> {



    render() {
        const { provisionableType, provisionableVersion, generateStructuralDag, diffsInDag } = this.props;

        return (
            <Container>
                <BannerContainer>
                    <Banner
                        appearance="warning"
                        testId="info-banner">
                        DEP Project is in experimental stage, all data is mocked.
                    </Banner>
                </BannerContainer>
                <Label htmlFor="provisionableType-select">Provisionable Type</Label>
                <Select
                    inputId="provisionableType-select"
                    options={provisionableType.options}
                    placeholder="Provisionable"
                    value={provisionableType.value}
                    onChange={provisionableType.onSelectChange}
                />
                <PaddedDiv/>
                <Label htmlFor="provisionableVersion-select">Version</Label>
                <Select
                    inputId="provisionableVersion-select"
                    options={provisionableVersion.options}
                    placeholder="Version"
                    value={provisionableVersion.value}
                    onChange={provisionableVersion.onSelectChange}
                />
                <PaddedDiv/>
                <div className={'container'}>
                    <Button appearance="primary"
                        onClick={()=>generateStructuralDag(provisionableType.value?.value, provisionableVersion.value?.value)}
                        isDisabled={!provisionableType.value || !provisionableVersion.value } >
                        Generate Structural DAG
                    </Button>
                </div>
                <PaddedDiv >
                    <Label style={{paddingRight: 4}}>Changes from previous version:</Label>
                    <div>
                        <PaddedDiv>
                            <Label>Removed</Label>
                            {diffsInDag?.removed.map((item: any) => {
                                return <div key={item.id}> {item.id}</div>
                            })}
                        </PaddedDiv>
                        <PaddedDiv>
                            <Label>Added</Label>
                            {diffsInDag?.added.map((item: any) => {
                                return <div key={item.id}> {item.id}</div>
                            })}
                        </PaddedDiv>
                    </div>
                </PaddedDiv>
            </Container>
        );
    }
}

export default DEPInputForm;