/** @jsx jsx */
import React, {Component} from 'react';

import { jsx } from '@emotion/core';

import Button from '@atlaskit/button/standard-button';

import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from '@atlaskit/modal-dialog';
import {NodeInfo} from "./Types";
import {CodeBlock} from "@atlaskit/code";

export interface NodeInfoModalProps {
    onClose: () => void;
    nodeInfo: NodeInfo | undefined;
}

interface NodeInfoModalState {
    isVisible: boolean;
}

class NodeInfoModal extends Component<NodeInfoModalProps, NodeInfoModalState> {

    constructor(props: NodeInfoModalProps) {
        super(props);
        this.state = {isVisible: true};
        this.closeClick = this.closeClick.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<NodeInfoModalProps>, prevState: Readonly<NodeInfoModalState>, snapshot?: any) {
        if (this.props.nodeInfo != undefined && prevProps.nodeInfo != this.props.nodeInfo) {
            this.setState({  isVisible: true });
        }
    }

    closeClick() {
        this.setState({  isVisible: false });
        this.props.onClose();
    }

    render() {
        const nodeInfo = this.props.nodeInfo;
        const failureDetail = nodeInfo?.failureDetail;

        return (
            <div>
                <ModalTransition>
                    {this.state.isVisible && (
                        <Modal>
                            <ModalHeader>
                                {
                                    <ModalTitle appearance={ failureDetail ? "danger" : undefined } >{nodeInfo?.activityName}</ModalTitle>
                                }

                            </ModalHeader>
                            <ModalBody>
                                {
                                    nodeInfo?.activityStatusDesc &&
                                    <div>
                                        <span>{nodeInfo?.activityStatusDesc}</span>
                                    </div>
                                }
                                {
                                    nodeInfo?.activityStatusDesc && (failureDetail?.reason || failureDetail?.detail) &&
                                    <br/>
                                }
                                {
                                    failureDetail?.reason &&
                                    <div>
                                        <b>Reason:</b><br/>
                                        <CodeBlock showLineNumbers={true} text={failureDetail?.reason}/><br/>
                                    </div>
                                }
                                {
                                    failureDetail?.detail &&
                                    <div>
                                        <b>Detail:</b><br/>
                                        <CodeBlock showLineNumbers={true} text={failureDetail?.detail}/>
                                    </div>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button appearance="primary" onClick={this.closeClick}>Close</Button>
                            </ModalFooter>
                        </Modal>
                    )}
                </ModalTransition>
            </div>
        );
    }
}

export default NodeInfoModal;