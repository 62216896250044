import React, { Component } from 'react';
import { Content, LeftSidebar, Main } from '@atlaskit/page-layout';
import { gridSize } from '@atlaskit/theme/constants';
import styled from '@emotion/styled';

import {
    extractGroupName,
    fetchDag,
    fetchDagByWorkFlowId,
    fetchFeatureFlags,
    fetchSelectableRequestTypes,
    fetchTemplates,
    fetchWorkFlowStatus,
    getAllGroups,
    isGroupStartNode,
    computeNewDag,
    isCollapsedGroupNode,
    parseError,
    fetchProvisionableTypes,
    fetchProvisionableVersions,
} from '../common/utils';
import { ObjectSelectable, RequestSelectionState, Selectable, TemplateMeta } from '../types';
import ErrorPane, {ErrorMessage} from './ErrorPane';
import LoadAChu from './LoadAChu';
import DagDisplay from './dag/DagDisplay';
import {FailureDetail, RequestItem} from './dag/Types';
import RequestInputForm from './RequestInputForm';
import { WorkflowStatus } from './dag/DagDisplay';
import {getAllFeatureFlagsFromTemplateMeta} from "./utils";
import {DagNode, sampleDag} from "./dag/sample-data/sample-dag";

interface WrapperProps {
    minHeight?: string;
    noHorizontalScrollbar?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
    padding: ${gridSize() * 2}px;
    min-height: ${(props) => props.minHeight};
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: ${(props) => (props.noHorizontalScrollbar ? 'hidden' : 'auto')};
    background-color: white;
`;

interface WorkflowInspectorProps {
    environmentBaseUrl: string;
    environmentOverrides: Selectable[];
    providedDag?: { dag: RequestItem[]; request: RequestSelectionState };
}

interface WorkflowInspectorState {
    loading: boolean;
    errorMessages: ErrorMessage[];
    completeDag?: RequestItem[];
    currentDag?: RequestItem[];    
    // TODO naming of request is kind of confusing
    collapsedGroups: string[];
    request?: RequestSelectionState;
    isReadOnly: boolean;
    requestTypes: Selectable[];
    featureFlags: any;
    templateMeta: TemplateMeta | null;
    showDagWithStatus: boolean;
    workflowStatus?: WorkflowStatus;
    featureFlagOptions?: ObjectSelectable[];
    provisionableTypes?: Selectable[];
    provisionableVersions?: Selectable[];
    diffsInDag: any;

}

const baseFeatureFlagSelections: ObjectSelectable[] = [
    {
        label: 'emptyFeatureFlagSelection',
        value: {},
    },
];

class WorkflowInspector extends Component<WorkflowInspectorProps, WorkflowInspectorState> {
    constructor(props: WorkflowInspectorProps) {
        super(props);
        this.state = {
            loading: false,
            errorMessages: [],
            completeDag: props.providedDag ? props.providedDag.dag : undefined,
            currentDag: props.providedDag ? props.providedDag.dag : undefined,
            collapsedGroups: [],
            request: props.providedDag ? props.providedDag.request : undefined,
            isReadOnly: Boolean(props.providedDag),
            requestTypes: [],
            templateMeta: null,
            featureFlags: {},
            showDagWithStatus: false,
            workflowStatus: undefined,
            diffsInDag: undefined,
        };
        this.nodeClicked = this.nodeClicked.bind(this)
    }

    componentDidMount() {
        fetchSelectableRequestTypes()
            .then((result) => {
                this.setState({
                    ...this.state,
                    requestTypes: result,
                });
            })
            .catch(this.handleError);
        fetchTemplates()
            .then((result) => {
                const combinedFeatureFlags = getAllFeatureFlagsFromTemplateMeta(result);
                this.setState({
                    ...this.state,
                    featureFlagOptions:
                        Object.keys(combinedFeatureFlags).length === 0
                            ? baseFeatureFlagSelections
                            : baseFeatureFlagSelections.concat(
                                  Object.keys(combinedFeatureFlags).map((key) => {
                                      return {
                                          label: key,
                                          value: combinedFeatureFlags[key],
                                      };
                                  }),
                              ),
                    templateMeta: result,
                });
            })
            .catch(this.handleError);
        fetchFeatureFlags()
            .then((result) => {
                this.setState({
                    ...this.state,
                    featureFlags: result,
                });
            })
            .catch(this.handleError);

        // Dynamic Entity
        fetchProvisionableTypes()
            .then((result) => {
                this.setState({
                    ...this.state,
                    provisionableTypes: result,
                });
            })
            .catch(this.handleError);
        fetchProvisionableVersions()
            .then((result) => {
                this.setState({
                    ...this.state,
                    provisionableVersions: result,
                });
            })
            .catch(this.handleError);
        fetchSelectableRequestTypes()
            .then((result) => {
                this.setState({
                    ...this.state,
                    requestTypes: result,
                });
            })
            .catch(this.handleError);
    }

    handleExport = () => {
        const el = document.createElement('textarea');
        el.value =
            this.props.environmentBaseUrl +
            // Pull out the request name (filtering out all other fields - as we currently don't use them)
            '?request=' +
            JSON.stringify(this.state.request, ['selectedRequestType']) +
            // Pull out the current dag shape
            '&dag=' +
            JSON.stringify(this.state.currentDag);
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert('copied to clipboard');
    };


    generateNewDag = (req: RequestSelectionState) => {
        this.setState({ loading: true });
        fetchDag(
            req.selectedRequestType!.value,
            req.state,
            req.input,
            req.featureFlags,
            req.entityId,
            req.microsEnvironment!.value,
        )
            .then((dag) => {
                this.setState({
                    currentDag: dag,
                    completeDag: dag,
                    request: req,
                    loading: false,
                    showDagWithStatus: false,
                });
            })
            .catch(this.handleError);
    };

    generateDagByWorkflowId = (workflowId: string) => {
        this.clearErrorMessages();
        this.setState({ loading: true });
        this.setState({
            currentDag: [],
        });
        fetchDagByWorkFlowId(workflowId)
            .then((dag) => {
                if (dag.length === 0) {
                    this.handleError( 'Received empty list from back end service' );
                    return;
                }

                this.setState({
                    currentDag: dag,
                    completeDag: dag,
                    loading: false
                });
                fetchWorkFlowStatus(workflowId).then((status) => {
                    this.updateGroupStatus(dag, status);
                });
            })
            .catch(this.handleError);
    };

    // Dynamic Entity Structural Dag
    generateStructuralDag = (provisionableType: string, provisionableVersion:string) => {
        this.clearErrorMessages();
        this.setState({ loading: true });

        const requestedDag: DagNode | undefined = sampleDag.find(node => node.product == provisionableType && node.version == provisionableVersion);
        const previousVersionDag: DagNode | undefined = sampleDag.find(node => node.product == provisionableType && node.version != provisionableVersion);

        const diffs = this.diffFinder(requestedDag, previousVersionDag);

        return Promise.resolve(requestedDag?.payload).then((dag) => {
            this.setState({
                diffsInDag: diffs,
                currentDag: dag,
                completeDag: dag,
                loading: false,
                showDagWithStatus: false,
                request: {selectedRequestType: {value: 'foo', label: 'foo'},
                    selectedInputTemplate: {value: 'foo', label: 'foo'},
                    selectedStateTemplate: {value: 'foo', label: 'foo'},
                    state: null,
                    input: null,
                    featureFlags: {},
                    entityId: "foo",
                    featureFlagOption: null,
                    featureFlagSelection: null,
                    microsEnvironment: {value: 'foo', label: 'foo'},
                    selectedProvisionableType:  null,
                    selectedProvisionableVersion: null
                }
            });
        });
    };

     diffFinder = (requestedDag?: DagNode, laterDag?: DagNode): any => {

        if (!laterDag || !requestedDag || (requestedDag.version <= laterDag.version)) {
            return { added: [], removed: [] };
        }
        const added = laterDag.payload.filter(node => !requestedDag.payload.some(firstNode => firstNode.id === node.id));
        const removed = requestedDag.payload.filter(node => !laterDag.payload.some(laterNode => laterNode.id === node.id));

        return { added, removed };
    }

    updateGroupStatus = (items: RequestItem[], status: WorkflowStatus) => {
        status.partiallyCompleted = [];
        items?.filter(i => i.id.startsWith('END[')).map(end => {
            const startId = end.id.replace('END[', 'START[');
            const groupId = end.id.replace('END[', 'GROUP[');
            const activityProps = this.initializeActivityProperties();
            this.inspectNodesParentsStatus(startId, end.id, items, status, activityProps);
            if (activityProps.activities > 0) {
                if (activityProps.activities === activityProps.completed) {
                    status.completed.push(startId, end.id, groupId);
                } else if (activityProps.errors > 0) {
                    if (activityProps.completed === 0) {
                        Object.assign(status.errors, {
                            [startId]: this.generateFailureOrPartialDetail(true),
                            [end.id]: this.generateFailureOrPartialDetail(true),
                            [groupId]: this.generateFailureOrPartialDetail(true)
                        });
                    } else {
                        Object.assign(status.partiallyCompleted, {
                            [startId]: this.generateFailureOrPartialDetail(false),
                            [end.id]: this.generateFailureOrPartialDetail(false),
                            [groupId]: this.generateFailureOrPartialDetail(false)
                        });
                    }
                }
            }
        });
        this.setState({
            workflowStatus: status,
            showDagWithStatus: true
        });
    };

    initializeActivityProperties = (): ActivityStatusProps => {
        return {
            activities: 0,
            completed: 0,
            errors: 0
        };
    }

    generateFailureOrPartialDetail = (isFailure: boolean): FailureDetail => {
        return {
            failureType: isFailure ? 'FAILURE' : 'PARTIALLY COMPLETED',
            reason:  isFailure ? 'Activities in the group failed' : 'One or more activities in the group failed',
            detail: ''
        };
    }

    inspectNodesParentsStatus = (startId: string, currentId: string, items: RequestItem[], status: WorkflowStatus, activityProps: ActivityStatusProps ) => {
        const node = items.find(i => i.id === currentId);
        node?.parentIds.map(parentId => {
           const parent = items.find(i => i.id === parentId);
           if (parent && parent.id !== startId && !parent.id.startsWith('START[')) {
               activityProps.activities += 1;
               activityProps.completed += status.completed && status.completed.includes(parent.id) ? 1 : 0;
               activityProps.errors += status.errors && Object.keys(status.errors).includes(parent.id) ? 1 : 0;
               this.inspectNodesParentsStatus(startId, parent.id, items, status, activityProps);
           }
        });
    };
    
    nodeClicked = (node: RequestItem) => {        
        if (isGroupStartNode(node) || isCollapsedGroupNode(node)) {
            const groupName: string = extractGroupName(node)
            const newCollapsedGroups = this.state.collapsedGroups.includes(groupName) ?
                this.state.collapsedGroups.filter(group => group !== groupName) :
                [...this.state.collapsedGroups, groupName]          
            this.setState({
                currentDag: computeNewDag(this.state.completeDag!, newCollapsedGroups),
                collapsedGroups: newCollapsedGroups
            })
        }
    }

    collapseAll = () => {
        const groups = Array.from(getAllGroups(this.state.completeDag!))
        this.setState({
            currentDag: computeNewDag(this.state.completeDag!, groups),
            collapsedGroups: groups
        })
    }

    expandAll = () => {
        this.setState({
            currentDag: computeNewDag(this.state.completeDag!, []),
            collapsedGroups: []
        })
    }

    // TODO unify with other error pane/error handling?
    handleError = (errorObject: any) => {
        if (
            errorObject === undefined ||
            errorObject === null ||
            Object.keys(errorObject).length === 0
        ) {
            return;
        }
        if (this.state.errorMessages.length <= 5) {
            this.setState({
                loading: false,
                errorMessages: this.state.errorMessages.concat(parseError(errorObject)),
            });
        } else {
            console.log(errorObject);
        }
        if (this.state.errorMessages.length === 5) {
            this.setState({
                loading: false,
                errorMessages: this.state.errorMessages.concat(
                    { message: 'Message limit exceeded. Further error messages ' + 'will be logged to console'}
                ),
            });
        }
    };

    clearErrorMessage = (indexToClear: number) => {
        this.setState({
            errorMessages: this.state.errorMessages.filter(
                (_, errorIndex) => errorIndex !== indexToClear,
            ),
        });
    };

    clearErrorMessages = () => {
        this.setState({
            errorMessages: [],
        });
    };

    render() {
        const shouldDisplayDagLive = !this.state.loading &&
            this.state.currentDag &&
            this.state.currentDag.length !== 0 &&
            this.state.showDagWithStatus;
        return (
            <Content testId="content">
                <LeftSidebar
                    testId="leftSidebar"
                    id="workflow-input"
                    skipLinkTitle="Workflow Input"
                    width={500}
                    collapsedState={this.state.isReadOnly ? 'collapsed' : 'expanded'}
                >
                    <Wrapper noHorizontalScrollbar>
                        <h4 style={{ textAlign: 'center' }}>Workflow Input</h4>
                        {this.state.isReadOnly ? null : (
                            <RequestInputForm
                                generateNewDag={this.generateNewDag}
                                generateDagByWorkflowId={this.generateDagByWorkflowId}
                                generateStructuralDag={this.generateStructuralDag}
                                environmentOverrides={this.props.environmentOverrides}
                                requestTypes={this.state.requestTypes}
                                templateMeta={this.state.templateMeta}
                                featureFlags={this.state.featureFlags}
                                workflowStatus={this.state.workflowStatus}
                                featureFlagOptions={this.state.featureFlagOptions}
                                provisionableTypes={this.state.provisionableTypes}
                                provisionableVersions={this.state.provisionableVersions}
                                diffsInDag={this.state.diffsInDag}
                            />
                        )}
                    </Wrapper>
                </LeftSidebar>

                <Main testId="main" id="main" skipLinkTitle="Main Content">
                    <Wrapper minHeight="400px">
                        <h4 style={{ textAlign: 'center' }}>Workflow Output</h4>
                        <ErrorPane
                            title="Something went wrong"
                            errorMessages={this.state.errorMessages}
                            onErrorPaneClick={this.clearErrorMessage}
                            onDismissClick={this.clearErrorMessages}
                        />
                        <div className={'dag-view'}>
                            {!this.state.loading && !this.state.currentDag && (
                                <>
                                    <p>
                                        Select a request type on the left and click Calculate to
                                        generate a DAG.
                                    </p>
                                    <LoadAChu />
                                </>
                            )}
                            {!this.state.loading && this.state.currentDag && (
                                <div className={"mr-t-b-10"}>
                                    <button
                                        className={'dependency-button flex-child'}
                                        onClick={this.collapseAll}
                                    >
                                        Collapse All Groups
                                    </button>
                                    <button
                                        className={'dependency-button flex-child'}
                                        onClick={this.expandAll}
                                    >
                                        Expand All Groups
                                    </button>
                                </div>
                            )
                            }
                            {!this.state.loading &&
                                this.state.currentDag &&
                                this.state.request &&
                                !this.state.showDagWithStatus && (
                                    <>                                        
                                        <DagDisplay
                                            dag={this.state.currentDag}
                                            requestName={this.state.request.selectedRequestType!.value}
                                            inputName={this.state.request.selectedInputTemplate!.value}
                                            stateName={this.state.request.selectedStateTemplate!.value}
                                            onNodeClicked={this.nodeClicked}
                                            maxNodes={this.state.completeDag!.length}
                                        />
                                    </>
                                    
                                )}
                            {shouldDisplayDagLive && (
                                <DagDisplay
                                    dag={this.state.currentDag}
                                    status={this.state.workflowStatus}
                                    onNodeClicked={this.nodeClicked}
                                    maxNodes={this.state.completeDag!.length}
                                    live={true}
                                />
                            )}
                            {this.state.loading && <LoadAChu/>}
                        </div>

                        {this.state.isReadOnly ? null : (
                            <button
                                className={'dependency-button flex-child mr-t-b-10'}
                                onClick={this.handleExport}
                            >
                                Export
                            </button>
                        )}                            
                    </Wrapper>
                </Main>
            </Content>
        );
    }
}

type ActivityStatusProps = {
    activities: number;
    completed: number;
    errors: number;
};

export default WorkflowInspector;
