import React, { Component } from 'react';
import Select from '@atlaskit/select';
import JSONInput from 'react-json-editor-ajrm';
// @ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import { gridSize } from '@atlaskit/theme/constants';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import {
    SelectAndJSONInputProps,
    SelectProps,
    TextFieldProps,
    ObjectSelectAndJSONInputProps,
} from './types';
import {Label, PaddedDiv} from './Forms.styles';

export interface TemplateInputFormProps {
    requestType: SelectProps;
    input: SelectAndJSONInputProps;
    state: SelectAndJSONInputProps;
    featureFlags: ObjectSelectAndJSONInputProps;
    entityId: TextFieldProps;
    microsEnvironment: SelectProps;
    onSubmit: () => void;
}

export const jsonInputStyle = {
    outerBox: { width: 100 + '%' },
    container: { width: 100 + '%' },
    body: { width: 100 + '%', borderRadius: '0px 0px 5px 5px' },
    labelColumn: { position: 'absolute', right: '0px', zIndex: '999', opacity: '25%' },
};

export const MILLISECONDS_BEFORE_VALIDATION = 500;

class TemplateInputForm extends Component<TemplateInputFormProps> {
    render() {
        const { requestType, input, state, featureFlags, entityId, microsEnvironment, onSubmit } =
            this.props;

        return (
            <div style={{ width: '100%' }}>
                <Label htmlFor="request-select">Request</Label>
                <Select
                    inputId="request-select"
                    options={requestType.options}
                    placeholder="Choose a Request Type"
                    value={requestType.value}
                    onChange={requestType.onSelectChange}
                />
                <PaddedDiv/>
                <Label htmlFor="input-select">Input</Label>
                <Select
                    inputId="input-select"
                    options={input.options}
                    placeholder="Choose an Input for the Request Type"
                    value={input.value}
                    onChange={input.onSelectChange}
                />
                <div className={'textArea'}>
                    <JSONInput
                        height="180px"
                        waitAfterKeyPress={MILLISECONDS_BEFORE_VALIDATION}
                        placeholder={input.placeholder}
                        onChange={input.onInputChange}
                        locale={locale}
                        style={jsonInputStyle}
                    />
                </div>
                <PaddedDiv/>
                <Label htmlFor="state-select">State</Label>
                <Select
                    inputId="state-select"
                    options={state.options}
                    placeholder="Choose a State for the Request"
                    value={state.value}
                    onChange={state.onSelectChange}
                />
                <div className={'textArea'}>
                    <JSONInput
                        height="180px"
                        waitAfterKeyPress={MILLISECONDS_BEFORE_VALIDATION}
                        placeholder={state.placeholder}
                        onChange={state.onInputChange}
                        locale={locale}
                        style={jsonInputStyle}
                    />
                </div>
                <PaddedDiv/>
                <Label htmlFor="feature-flag">Feature Flags</Label>
                <Select
                    inputId="feature-flag-select"
                    options={featureFlags.options}
                    placeholder="Choose a Feature Flag setting"
                    value={featureFlags.value}
                    onChange={featureFlags.onSelectChange}
                />
                <div className={'textArea'}>
                    <JSONInput
                        height="180px"
                        waitAfterKeyPress={MILLISECONDS_BEFORE_VALIDATION}
                        placeholder={featureFlags.placeholder}
                        onChange={featureFlags.onInputChange}
                        locale={locale}
                        style={jsonInputStyle}
                    />
                </div>
                <PaddedDiv/>
                <div className={'input'}>
                    <h5 style={{ marginTop: gridSize() }}>Settings</h5>
                    <Label htmlFor="entityid">Entity Id</Label>
                    <Textfield
                        testId="entityid"
                        value={entityId.value}
                        onChange={entityId.onChange}
                    />
                    <Label htmlFor="environment-select">Micros Environment</Label>
                    <Select
                        inputId="environment-select"
                        options={microsEnvironment.options}
                        value={microsEnvironment.value}
                        onChange={microsEnvironment.onSelectChange}
                    />
                </div>
                <div className={'container'}>
                    <Button
                        appearance="primary"
                        onClick={onSubmit}
                        isDisabled={!requestType.value || !input.value || !state.value}
                    >
                        Calculate
                    </Button>
                </div>
            </div>
        );
    }
}

export default TemplateInputForm;
