import React, { useState } from 'react';
import { Popup } from '@atlaskit/popup';
import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { Selectable } from '../../types';
import { environments } from '../../constants';

interface RegionOptionsProps {
    environments: Selectable[];
    handleRegionChange: any;
}

const RegionOptions = ({ environments, handleRegionChange }: RegionOptionsProps) => {
    return (
        <MenuGroup>
            <Section>
                {environments.map((env) => {
                    return (
                        <ButtonItem key={env.label} onClick={() => handleRegionChange(env.value)}>
                            {env.label}
                        </ButtonItem>
                    );
                })}
            </Section>
        </MenuGroup>
    );
};

const RegionSwitcher = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOnClick = () => {
        setIsOpen((prev) => !prev);
    };

    const handleOnClose = () => {
        setIsOpen(false);
    };

    const handleRegionChange = (value: string) => {
        window.open(value, '_self');
    };

    return (
        <Popup
            isOpen={isOpen}
            onClose={handleOnClose}
            placement={'bottom-start'}
            content={() => (
                <RegionOptions
                    environments={environments}
                    handleRegionChange={handleRegionChange}
                />
            )}
            trigger={(triggerProps) => (
                <PrimaryDropdownButton
                    {...triggerProps}
                    isSelected={isOpen}
                    onClick={handleOnClick}
                >
                    Region
                </PrimaryDropdownButton>
            )}
        />
    );
};

export default RegionSwitcher;
