import * as d3 from 'd3';
import React, { Component } from 'react';
import { GraphSettings } from './Types';

class Title extends Component<{ settings: GraphSettings; title: string }> {
    ref!: SVGGElement;

    componentDidMount() {
        const context = d3.select(this.ref);
        const title = this.props.title;
        const titleEl = context
            .selectAll('.title')
            .data([title])
            .enter()
            .append('g')
            .attr('class', 'title')
            .attr('style', 'font-weight:bold; font-size:14px; font-family: sans-serif') // inline styles, so export works
            .attr('transform', () => 'translate(15, 15)');
        titleEl.append('text').attr('x', 0).attr('y', 0).text(title);
    }

    render() {
        return <g className="title" ref={(ref: SVGGElement) => (this.ref = ref)} />;
    }
}

export default Title;
