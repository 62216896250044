import { TemplateMeta } from '../types';

export const getAllFeatureFlagsFromTemplateMeta = (
    templateMeta: TemplateMeta | null,
): { [name: string]: { [name: string]: boolean } } => {
    let combinedFeatureFlags = {};
    if (templateMeta?.CLOUD?.featureFlags != null) {
        combinedFeatureFlags = {
            ...combinedFeatureFlags,
            ...templateMeta?.CLOUD?.featureFlags,
        };
    }
    if (templateMeta?.ORGANIZATION?.featureFlags != null) {
        combinedFeatureFlags = {
            ...combinedFeatureFlags,
            ...templateMeta.ORGANIZATION.featureFlags,
        };
    }
    return combinedFeatureFlags;
};
