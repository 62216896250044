import * as d3 from 'd3';
import {
    coordQuad,
    coordSimplex,
    dagStratify,
    decrossOpt,
    decrossTwoLayer,
    layeringSimplex,
    sugiyama,
} from 'd3-dag';
import { Dag } from 'd3-dag/dist/dag/node';
import React, { Component } from 'react';
import Links from './Links';
import Nodes from './Nodes';
import Title from './Title';
import {d3DagNodeClass, FailureDetail, GraphSettings, NodeInfo, RequestItem} from './Types';
import Legend from './Legend';
import { Operator as DecrossOperator } from 'd3-dag/dist/sugiyama/decross';
import {hasStartNode, isActivityNode, STATUS_COLOR_MAPPING} from "../../common/utils";
import NodeInfoModal from "./NodeInfoModal";
import StatusExamples from "../StatusExamples";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface StatusErrors {
    [key: string]: FailureDetail
}

export interface WorkflowStatus {
    workflowId: string;
    status: string;
    completed: string[];
    errors: StatusErrors[];
    partiallyCompleted: StatusErrors[];
    inflight: string[];
    started: string;
    closed: string;
    tags: [];
}

/**
 * DAGDisplay parent object.
 *
 * This component handles the rendering of the given request DAG shape.
 * Using D3 with React is a bit awkward, but we can force it a bit and get some level
 * of components. (ref: https://spin.atomicobject.com/2017/07/20/d3-react-typescript/ for some context)
 */
class DagDisplay extends Component<Props, State> {
    ref!: HTMLDivElement;

    constructor(props: Props) {        
        super(props);
        this.nodeInfoModalClosed = this.nodeInfoModalClosed.bind(this);
        this.nodeClicked = this.nodeClicked.bind(this);
        this.state = this.defineDagState(props);
    }

    static determineDecrossingAlgo(numberOfEdges: number): DecrossOperator<any> {
        if (numberOfEdges > 30) return decrossTwoLayer();
        return decrossOpt();
    }

    static determineCoordAlgo(numberOfNodes: number) {
        return numberOfNodes > 50 ? coordSimplex() : coordQuad()
    }

    // Build up a unique color mapping for each node
    static buildColorMap(dag: Dag<d3DagNodeClass>): Map<string, string> {
        const colorMap: Map<string, string> = new Map();
        const size = dag.size();
        dag.descendants()
            .map((node) => node.data.id)
            .forEach((id, index) => {
                colorMap.set(id, d3.interpolateRainbow(index / size));
            });
        return colorMap;
    }

    static surroundDAGWithStartAndFinish(request: RequestItem[]) {
        const startId = 'workflow-start';
        request.forEach((activity) => {
            const parentIds = activity.parentIds;
            if (Array.isArray(parentIds) && parentIds.length === 0) {
                parentIds.push(startId);
            }
        });
        request.push({
            id: startId,
            parentIds: [],
        });
        const storeParents: string[] = [];
        request.forEach((activity) => {
            const id = activity.id;
            const isTerminal =
                request.filter((subActivity) => subActivity.parentIds.includes(id)).length === 0;
            if (isTerminal) {
                storeParents.push(id);
            }
        });
        request.push({
            id: 'workflow-finish',
            parentIds: storeParents,
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        d3.select(this.ref).remove();
        if (prevProps.dag.length !== this.props.dag.length || this.props.status && prevProps.status != this.props.status) {
            this.setState(this.defineDagState(this.props))
        }
    }

    defineDagState(props: Props) {        
        const margin = { top: 50, right: 100, bottom: 50, left: 100 };
        // Stratify our DAG from a flat (tabular) structure to a DAG format
        const dag = dagStratify()(props.dag) as Dag<d3DagNodeClass>;
        // number of nodes in the DAG, min of 20
        const numberOfNodes = Math.max(20, dag.size());
        // Push the graph to the right to fit the legend, based on the max id size.
        let maxLength = 1;
        dag.descendants()
            .map((node) => node.data.id)
            .forEach((id) => {
                if (id.length > maxLength) {
                    maxLength = id.length;
                }
            });
        const width = numberOfNodes * 30;
        const height = numberOfNodes * 30;
        const layoutFunction = sugiyama()
            .size([width, height])
            .layering(layeringSimplex())
            .decross(DagDisplay.determineDecrossingAlgo(dag.links().length))
            .coord(DagDisplay.determineCoordAlgo(props.maxNodes));
        // Run the above layout function on this DAG, populating the x, y coords and layering information
        layoutFunction(dag);        
        const borderColorMap: Map<string, string> = new Map();
        const errors: Map<string, FailureDetail> = new Map();
        if (props.live) {
            dag.descendants()
            .map((node) => node.data.id)
            .forEach((id: string) => {
                if (this.props.status!.completed.includes(id)) {
                    borderColorMap.set(id, STATUS_COLOR_MAPPING.completed.color);
                    return;
                }

                if (this.props.status?.errors && Object.keys(this.props.status!.errors).includes(id)) {
                    borderColorMap.set(id, STATUS_COLOR_MAPPING.errors.color);
                    errors.set(id, this.props.status!.errors[id])
                    return;
                }

                if (this.props.status?.inflight && this.props.status!.inflight.includes(id)) {
                    borderColorMap.set(id, STATUS_COLOR_MAPPING.inflight.color);
                    return;
                }

                if (this.props.status?.errors && Object.keys(this.props.status!.partiallyCompleted).includes(id)) {
                    borderColorMap.set(id, STATUS_COLOR_MAPPING.partiallyCompleted.color);
                    errors.set(id, this.props.status!.partiallyCompleted[id])
                    return;
                }
            });
        }        
        const settings = {
            colorMap: DagDisplay.buildColorMap(dag),
            borderColorMap: borderColorMap,
            errors: errors,
            nodeRadius: 20,
            startX: 15 + maxLength,
            startY: 10,
            margin: margin,
        };
        return { dag, settings, width, height, margin }
    }

    componentDidMount() {
        const { margin } = this.state;
        // append the svg object to the body of the page
        // appends a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        const context = d3.select(this.ref);
        context
            .select('svg')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');        
    }

    render() {        
        const { width, height, margin } = this.state;
        const dagWidth = width + margin.left;
        const dagHeight = height + margin.top;
        const viewBoxParams = `0 0 ${dagWidth} ${dagHeight}`
        return (
            <>
            <div className="dagDisplay">
                <svg className="legend" width="100%" height={height + margin.top}>
                    <Title settings={this.state.settings} title={this.props.requestName} />
                    <Legend settings={this.state.settings} nodes={this.state.dag.descendants()} live={this.props.live} onNodeClicked={this.nodeClicked} />
                </svg>

                <div className="dagContainer">                                    
                    <TransformWrapper limitToBounds={false} centerZoomedOut={true} maxScale={5} minScale={0.3} wheel={{step:0.1, smoothStep: 0.005}}>
                        <TransformComponent wrapperClass="transformWrapper" contentClass="transformWrapper">
                            <svg className="container" viewBox={viewBoxParams} width="100%" height="100%">
                                <Links settings={this.state.settings} links={this.state.dag.links()} />                            
                                <Nodes settings={this.state.settings} nodes={this.state.dag.descendants()} onNodeClicked={this.nodeClicked} />
                            </svg>
                        </TransformComponent>
                    </TransformWrapper>
                </div>
                {
                    this.state.clickedNode &&
                    <NodeInfoModal nodeInfo={this.state.clickedNode} onClose={this.nodeInfoModalClosed}/>
                }                
            </div>
            <div>
                {
                    this.props.live &&
                    <StatusExamples />
                }
            </div>
            </>
        );
    }

    nodeClicked(node: RequestItem) {
        if (isActivityNode(node) && this.props.live) {
            const nodeInfo = {} as NodeInfo;
            nodeInfo.activityName = node.id;

            if (this.props.status!.completed?.includes(node.id)) {
                nodeInfo.activityStatusDesc = "This activity has been completed successfully."
            }

            if (this.props.status?.errors && Object.keys(this.props.status!.errors).includes(node.id)) {
                nodeInfo.failureDetail = this.props.status!.errors[node.id];
                nodeInfo.activityStatusDesc = "This activity has failed."
            }

            if (this.props.status!.inflight?.includes(node.id)) {
                nodeInfo.activityStatusDesc = "This activity is currently in progress."
            }

            this.setState({...this.state, clickedNode: nodeInfo});
        }
        this.props.onNodeClicked(node)
    }

    nodeInfoModalClosed() {
        this.setState({...this.state, clickedNode: undefined});
    }
}

type Props = {
    dag: RequestItem[];
    requestName: string;
    inputName: string;
    stateName: string;
    live?: boolean;
    status?: WorkflowStatus;
    onNodeClicked: Function;
    maxNodes: number
};

type State = {
    width: number;
    height: number;
    margin: { left: number; right: number; top: number; bottom: number };
    dag: Dag<d3DagNodeClass>;
    settings: GraphSettings;
    clickedNode?: NodeInfo | undefined;
};

export default DagDisplay;