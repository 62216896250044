import * as d3 from 'd3';
import React, { Component } from 'react';
import { d3DagLink, GraphSettings } from './Types';

/**
 * Similar to Nodes this component is actually all of the links in one component.
 * There are ways of singular components per node, but I think that is actually harder to
 * understand. Since it makes the d3 setup logic more awkward.
 */
class Links extends Component<Props> {
    ref!: SVGGElement;

    componentDidMount() {
        this.defineLines();
        this.defineArrows();
    }

    componentDidUpdate() {
        const context = d3.select(this.ref)
        context.selectAll('*').remove()
        this.defineLines();
        this.defineArrows();        
    }

    defineLines() {
        const context = d3.select(this.ref);
        const defs = context.append('defs'); // For gradients
        const colorMap = this.props.settings.colorMap;
        const startX = this.props.settings.startX;
        const startY = this.props.settings.startY;
        const line = d3
            .line<{ x: number; y: number }>()
            .curve(d3.curveCatmullRom)
            .x((d) => startX + d.x)
            .y((d) => startY + d.y);
        context
            .selectAll('path')
            .data(this.props.links)
            .enter()
            .append('path')
            .attr('d', ({ points }) => line(points))
            .attr('fill', 'none')
            .attr('stroke-width', 3)
            .attr('stroke', ({ source, target }) => {
                const gradId = `${source.data.id}-${target.data.id}`;
                const grad = defs
                    .append('linearGradient')
                    .attr('id', gradId)
                    .attr('gradientUnits', 'userSpaceOnUse')
                    .attr('x1', source.x)
                    .attr('x2', target.x)
                    .attr('y1', source.y)
                    .attr('y2', target.y);
                grad.append('stop')
                    .attr('offset', '0%')
                    .attr('stop-color', colorMap.get(source.data.id)!);
                grad.append('stop')
                    .attr('offset', '100%')
                    .attr('stop-color', colorMap.get(target.data.id)!);
                return `url(#${gradId})`;
            })
            .attr('id', ({ source }) => {
                return source.id;
            });
    }

    defineArrows() {
        const context = d3.select(this.ref);
        const nodeRadius = this.props.settings.nodeRadius;
        const colorMap = this.props.settings.colorMap;
        const startX = this.props.settings.startX;
        const startY = this.props.settings.startY;
        const arrow = d3
            .symbol()
            .type(d3.symbolTriangle)
            .size((nodeRadius * nodeRadius) / 5.0);
        context
            .append('g')
            .selectAll('path')
            .data(this.props.links)
            .enter()
            .append('path')
            .attr('d', arrow)
            .attr('transform', ({ points }) => {
                const [end, start] = points.reverse();
                // This sets the arrows the node radius (20) + a little bit (3) away from the node center, on the last line segment of the edge. This means that edges that only span ine level will work perfectly, but if the edge bends, this will be a little off.
                const dx = start.x - end.x;
                const dy = start.y - end.y;
                const scale = (nodeRadius * 1.15) / Math.sqrt(dx * dx + dy * dy);
                // This is the angle of the last line segment
                const angle = (Math.atan2(-dy, -dx) * 180) / Math.PI + 90;
                return `translate(${startX + end.x + dx * scale}, ${
                    startY + end.y + dy * scale
                }) rotate(${angle})`;
            })
            .attr('fill', ({ target }) => colorMap.get(target.data.id)!)
            .attr('stroke', 'white')
            .attr('stroke-width', 1.5);
    }

    render() {
        return <g className="links" ref={(ref: SVGGElement) => (this.ref = ref)} />;
    }
}

type Props = {
    settings: GraphSettings;
    links: d3DagLink[];
};

export default Links;
