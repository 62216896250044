
export interface DagNode {

    product: string;
    version: string;
    payload: any[];
}
export let sampleDag: DagNode[] = [];

let bitbucket_v1: DagNode = {
    product: "Bitbucket",
    version: "1.0",
    payload: [
        {
            "id": "START[bitbucket::PROVISIONING]",
            "parentIds": []
        },
        {
            "id": "admin-portfolio-validation-request",
            "parentIds": [
                "START[bitbucket::PROVISIONING]"
            ],
            "groupId": "bitbucket::PROVISIONING"
        },
        {
            "id": "cloud-provisioner-state-create-entity::create",
            "parentIds": [
                "START[bitbucket::PROVISIONING]"
            ],
            "groupId": "bitbucket::PROVISIONING"
        },
        {
            "id": "bitbucket-provision-new-workspace",
            "parentIds": [
                "admin-portfolio-validation-request"
            ],
            "groupId": "bitbucket::PROVISIONING"
        },
        {
            "id": "set-entity-status::ACTIVE",
            "parentIds": [
                "cloud-provisioner-state-create-entity::create",
                "user-management-create-entity"
            ],
            "groupId": "bitbucket::PROVISIONING"
        },
        {
            "id": "user-management-create-entity",
            "parentIds": [
                "bitbucket-provision-new-workspace"
            ],
            "groupId": "bitbucket::PROVISIONING"
        },
        {
            "id": "END[bitbucket::PROVISIONING]",
            "parentIds": [
                "set-entity-status::ACTIVE"
            ]
        }
    ]
}

let confluence_v1: DagNode = {
    product: "Confluence",
    version: "1.0",
    payload: [
        {
            "id": "START[confluence::PROVISIONING]",
            "parentIds": []
        },
        {
            "id": "START[entitlementsAndLicense::PROVISIONING]",
            "parentIds": [
                "START[confluence::PROVISIONING]"
            ]
        },
        {
            "id": "licensedata-activate",
            "parentIds": [
                "START[entitlementsAndLicense::PROVISIONING]"
            ],
            "groupId": "entitlementsAndLicense::PROVISIONING"
        },
        {
            "id": "entitlements-activate::/cloud/activate",
            "parentIds": [
                "START[entitlementsAndLicense::PROVISIONING]"
            ],
            "groupId": "entitlementsAndLicense::PROVISIONING"
        },
        {
            "id": "END[entitlementsAndLicense::PROVISIONING]",
            "parentIds": [
                "entitlements-activate::/cloud/activate",
                "licensedata-activate"
            ]
        },
        {
            "id": "START[userManagement::PROVISIONING]",
            "parentIds": [
                "END[entitlementsAndLicense::PROVISIONING]"
            ]
        },
        {
            "id": "START[confluencePlacement::PROVISIONING]",
            "parentIds": [
                "END[entitlementsAndLicense::PROVISIONING]"
            ]
        },
        {
            "id": "mep-provisioning-product-activation",
            "parentIds": [
                "START[userManagement::PROVISIONING]"
            ],
            "groupId": "userManagement::PROVISIONING"
        },
        {
            "id": "user-management-activation.default",
            "parentIds": [
                "START[userManagement::PROVISIONING]"
            ],
            "groupId": "userManagement::PROVISIONING"
        },
        {
            "id": "activation-id-activate::CONFLUENCE",
            "parentIds": [
                "START[confluencePlacement::PROVISIONING]"
            ],
            "groupId": "confluencePlacement::PROVISIONING"
        },
        {
            "id": "select-realm::CONFLUENCE",
            "parentIds": [
                "START[confluencePlacement::PROVISIONING]"
            ],
            "groupId": "confluencePlacement::PROVISIONING"
        },
        {
            "id": "END[userManagement::PROVISIONING]",
            "parentIds": [
                "mep-provisioning-product-activation",
                "user-management-activation.default"
            ]
        },
        {
            "id": "END[confluencePlacement::PROVISIONING]",
            "parentIds": [
                "activation-id-activate::CONFLUENCE",
                "select-shard::CONFLUENCE"
            ]
        },
        {
            "id": "select-shard::CONFLUENCE",
            "parentIds": [
                "select-realm::CONFLUENCE"
            ],
            "groupId": "confluencePlacement::PROVISIONING"
        },
        {
            "id": "START[cpManagedState::PROVISIONING]",
            "parentIds": [
                "END[confluencePlacement::PROVISIONING]",
                "END[userManagement::PROVISIONING]"
            ]
        },
        {
            "id": "provider-metadata::MANAGE_SECRET;object-store-activate::object-store-confluence",
            "parentIds": [
                "START[cpManagedState::PROVISIONING]"
            ],
            "groupId": "cpManagedState::PROVISIONING"
        },
        {
            "id": "provider-metadata::MANAGE_SECRET;entity-relationship-store-activate::entity-relationship-store-confluence",
            "parentIds": [
                "START[cpManagedState::PROVISIONING]"
            ],
            "groupId": "cpManagedState::PROVISIONING"
        },
        {
            "id": "END[cpManagedState::PROVISIONING]",
            "parentIds": [
                "provider-metadata::MANAGE_SECRET;entity-relationship-store-activate::entity-relationship-store-confluence",
                "provider-metadata::MANAGE_SECRET;object-store-activate::object-store-confluence"
            ]
        },
        {
            "id": "START[confluenceResources::PROVISIONING]",
            "parentIds": [
                "END[cpManagedState::PROVISIONING]"
            ]
        },
        {
            "id": "provider-shard-select-shard::collab-service-confluence",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provisioning_no_op::cCollab",
            "parentIds": [
                "provider-shard-select-shard::collab-service-confluence"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::entity-relationship-store-confluence",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "entity-relationship-store-activate::entity-relationship-store-confluence",
            "parentIds": [
                "provider-shard-select-shard::entity-relationship-store-confluence"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::content-services-confluence",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provisioning_no_op::contentService",
            "parentIds": [
                "provider-shard-select-shard::content-services-confluence"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::confluence-analytics",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provisioning_no_op::ccAnalytics",
            "parentIds": [
                "provider-shard-select-shard::confluence-analytics"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::cc-entityproperties-service",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provisioning_no_op::ccEntitiesProperties",
            "parentIds": [
                "provider-shard-select-shard::cc-entityproperties-service"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::cc-indexer-service",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provisioning_no_op::indexerService",
            "parentIds": [
                "provider-shard-select-shard::cc-indexer-service"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::cc-smarts-service",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provisioning_no_op::ccSmarts",
            "parentIds": [
                "provider-shard-select-shard::cc-smarts-service"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::object-store-confluence",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "object-store-activate::object-store-confluence",
            "parentIds": [
                "provider-shard-select-shard::object-store-confluence"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::media-confluence",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "media-activate::CONFLUENCE",
            "parentIds": [
                "provider-shard-select-shard::media-confluence"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::confluence-druid",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provisioning_no_op::druid",
            "parentIds": [
                "provider-shard-select-shard::confluence-druid"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::plato-confluence",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provisioning_no_op::plato",
            "parentIds": [
                "provider-shard-select-shard::plato-confluence"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::notification-platform-confluence",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provisioning_no_op::notificationPlatformConfluence",
            "parentIds": [
                "provider-shard-select-shard::notification-platform-confluence"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::cc-bandana-service",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provisioning_no_op::ccBandana",
            "parentIds": [
                "provider-shard-select-shard::cc-bandana-service"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::synchrony",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "synchrony-confluence-activation",
            "parentIds": [
                "provider-shard-select-shard::synchrony"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "monarch::Confluence",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provider-shard-select-shard::xpsearch-content-searcher-confluence",
            "parentIds": [
                "START[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "provisioning_no_op::xpSearchContent",
            "parentIds": [
                "provider-shard-select-shard::xpsearch-content-searcher-confluence"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "END[confluenceResources::PROVISIONING]",
            "parentIds": [
                "entity-relationship-store-activate::entity-relationship-store-confluence",
                "media-activate::CONFLUENCE",
                "monarch::Confluence",
                "object-store-activate::object-store-confluence",
                "provisioning_no_op::cCollab",
                "provisioning_no_op::ccAnalytics",
                "provisioning_no_op::ccBandana",
                "provisioning_no_op::ccEntitiesProperties",
                "provisioning_no_op::ccSmarts",
                "provisioning_no_op::contentService",
                "provisioning_no_op::druid",
                "provisioning_no_op::indexerService",
                "provisioning_no_op::notificationPlatformConfluence",
                "provisioning_no_op::plato",
                "provisioning_no_op::xpSearchContent",
                "synchrony-confluence-activation"
            ]
        },
        {
            "id": "confluence-activation",
            "parentIds": [
                "END[confluenceResources::PROVISIONING]"
            ],
            "groupId": "confluenceResources::PROVISIONING"
        },
        {
            "id": "END[confluence::PROVISIONING]",
            "parentIds": [
                "confluence-activation"
            ]
        }
    ]
}

const atlasDag: DagNode = {
    product: "Atlas",
    version: "1.0",
    payload: [
        {
            "id": "START[atlas::PROVISIONING]",
            "parentIds": []
        },
        {
            "id": "START[userManagement::PROVISIONING]",
            "parentIds": [
                "START[atlas::PROVISIONING]"
            ]
        },
        {
            "id": "START[atlasActivation::PROVISIONING]",
            "parentIds": [
                "START[atlas::PROVISIONING]"
            ]
        },
        {
            "id": "user-management-activation.default",
            "parentIds": [
                "START[userManagement::PROVISIONING]"
            ],
            "groupId": "userManagement::PROVISIONING"
        },
        {
            "id": "activation-id-activate::TOWNSQUARE",
            "parentIds": [
                "START[atlasActivation::PROVISIONING]"
            ],
            "groupId": "atlasActivation::PROVISIONING"
        },
        {
            "id": "END[userManagement::PROVISIONING]",
            "parentIds": [
                "user-management-activation.default"
            ]
        },
        {
            "id": "townsquare-activate::townsquare",
            "parentIds": [
                "activation-id-activate::TOWNSQUARE"
            ],
            "groupId": "atlasActivation::PROVISIONING"
        },
        {
            "id": "END[atlas::PROVISIONING]",
            "parentIds": [
                "END[atlasActivation::PROVISIONING]",
                "END[userManagement::PROVISIONING]"
            ]
        },
        {
            "id": "END[atlasActivation::PROVISIONING]",
            "parentIds": [
                "townsquare-activate::townsquare"
            ]
        }
    ]
}

const atlasDag_v2: DagNode = {
    product: "Atlas",
    version: "2.0",
    payload: [
        {
            "id": "START[atlas::PROVISIONING]",
            "parentIds": []
        },
        {
            "id": "START[userManagement::PROVISIONING]",
            "parentIds": [
                "START[atlas::PROVISIONING]"
            ]
        },
        {
            "id": "START[atlasActivation::PROVISIONING]",
            "parentIds": [
                "START[atlas::PROVISIONING]"
            ]
        },
        {
            "id": "user-management-activation.default",
            "parentIds": [
                "START[userManagement::PROVISIONING]"
            ],
            "groupId": "userManagement::PROVISIONING"
        },
        {
            "id": "mep-provisioning-product-activation",
            "parentIds": [
                "START[userManagement::PROVISIONING]"
            ],
            "groupId": "userManagement::PROVISIONING"
        },
        {
            "id": "activation-id-activate::TOWNSQUARE",
            "parentIds": [
                "START[atlasActivation::PROVISIONING]"
            ],
            "groupId": "atlasActivation::PROVISIONING"
        },
        {
            "id": "END[userManagement::PROVISIONING]",
            "parentIds": [
                "mep-provisioning-product-activation",
                "user-management-activation.default"
            ]
        },
        {
            "id": "entity-relationship-service-activate::entity-relationship-service",
            "parentIds": [
                "activation-id-activate::TOWNSQUARE"
            ],
            "groupId": "atlasActivation::PROVISIONING"
        },
        {
            "id": "END[atlas::PROVISIONING]",
            "parentIds": [
                "END[atlasActivation::PROVISIONING]",
                "END[userManagement::PROVISIONING]"
            ]
        },
        {
            "id": "END[atlasActivation::PROVISIONING]",
            "parentIds": [
                "entity-relationship-service-activate::entity-relationship-service"
            ]
        }
    ]
}

sampleDag.push(bitbucket_v1, atlasDag, confluence_v1, atlasDag_v2);



