import * as d3 from 'd3';
import React, { Component } from 'react';
import { d3DagNode, d3DagNodeClass, GraphSettings } from './Types';

class Legend extends Component<Props> {
    ref!: SVGGElement;

    componentDidMount() {
        this.defineLegend();
    }

    componentDidUpdate() {
        const context = d3.select(this.ref)
        context.selectAll('*').remove()
        this.defineLegend();
    }

    defineLegend() {
        const context = d3.select(this.ref);
        const colorMap = this.props.settings.colorMap;
        const borderColorMap = this.props.settings.borderColorMap ?? new Map();
        const legendRectSize = 12;
        const legendSpacing = 3;
        const legend = context
            .selectAll('.legend')
            .data(this.props.nodes)
            .enter()
            .append('g')
            .attr('class', 'legend')
            .attr('style', 'font-size:12px; font-family: sans-serif') // inline styles, so export works
            .attr('transform', (d, i) => {
                const height = legendRectSize + legendSpacing;
                const offset = height * 3;
                const horz = legendRectSize;
                const vert = (i + 1) * height - offset + 50;
                return 'translate(' + horz + ',' + vert + ')';
            });

        legend
            .append('rect')
            .attr('width', legendRectSize)
            .attr('height', legendRectSize)
            .style('fill', (node) => colorMap.get(node.data.id)!)
            .style('stroke', (node) => this.props.live ? borderColorMap.get(node.data.id) : '#000000');

        legend
            .append('text')
            .attr('x', legendRectSize + legendSpacing)
            .attr('y', legendRectSize - legendSpacing)
            .text((node) => node.data.id)
            .style('cursor', (node) => !this.props.live || this.isGroupEndNode(node.data.id) ? 'default' : 'pointer')
            .on('click', (e) => {
                this.nodeClicked(e)
            });

        // update node circle on mouseover
        legend.on('mouseover', (node: d3DagNodeClass) => this.onMouseOverLegend(node.data.id));
        legend.on('mouseout', (node: d3DagNodeClass) => this.onMouseOutLegend(node.data.id));
    }

    onMouseOverLegend(elemId: string) {
        // set opactiy of all path elems (line + arrows) => 0.4
        d3.selectAll('path').transition().duration(200).style('opacity', 0.4);

        // set opacity of all circles => 0.4
        d3.selectAll('circle').transition().duration(200).style('opacity', 0.4);

        // IMP: using the attribute selector in the case there is "." in the
        // ActivityId e.g. monarch-destruction.confluence
        // jQuery needs to escape a "." as a literal
        d3.selectAll('[id="' + elemId + '"]')
            .select('circle')
            .transition()
            .duration(200)
            .attr('r', 30)
            .style('opacity', 1)
            .style('stroke-width', '2')
            .style('stroke', 'black');
    }

    onMouseOutLegend(elemId: string) {
        // reset to original opacity
        d3.selectAll('path').transition().duration(200).style('opacity', 1);

        d3.selectAll('circle')
            .transition()
            .duration(200)
            .attr('r', 20)
            .style('stroke-width', '')
            .style('stroke', '')
            .style('opacity', 1.0);
    }

    nodeClicked = (node: any) => {
        this.props.onNodeClicked(node.data)
    }

    render() {
        return <g className="legend" ref={(ref: SVGGElement) => (this.ref = ref)} />;
    }

    isGroupEndNode = (id: string): boolean => {
        return id.startsWith('END[');
    }
}

type Props = {
    settings: GraphSettings;
    nodes: d3DagNode[];
    live: boolean;
    onNodeClicked: Function;
};

export default Legend;
