import React, { Component } from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 20px;
`;

interface StatusExampleProp {
    color: string;
    text: string;
}

class StatusExample extends Component<StatusExampleProp> {
    render() {
        return (
            <Container>
                <svg height="60" width="60">
                    <circle
                        cx="30"
                        cy="30"
                        r="20"
                        stroke={this.props.color}
                        strokeWidth="3"
                        fill="transparent"
                    />
                </svg>
                <p style={{width:"90px", maxWidth:"90px", textAlign:"center"}}>{this.props.text}</p>
            </Container>
        );
    }
}

export default StatusExample;
