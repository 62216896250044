import React from 'react';
import { AtlassianNavigation, PrimaryButton, ProductHome } from '@atlaskit/atlassian-navigation';
import { AtlassianIcon, AtlassianLogo } from '@atlaskit/logo';
import RegionSwitcher from './RegionSwitcher';

const NavigationHeader = () => {
    const openSwaggerDocs = () => {
        window.open('/swagger-ui/index.html', '_blank');
    };

    return (
        <AtlassianNavigation
            label={'CP Workflows UI'}
            renderProductHome={() => (
                <ProductHome
                    icon={AtlassianIcon}
                    logo={AtlassianLogo}
                    siteTitle={'Cloud Provisioner Workflows UI'}
                />
            )}
            primaryItems={[
                <PrimaryButton onClick={openSwaggerDocs}>Swagger Docs</PrimaryButton>,
                <RegionSwitcher />,
            ]}
        />
    );
};

export default NavigationHeader;
