import React, { FC, useState } from 'react';
import {
    ObjectSelectable,
    RequestSelectionState,
    Selectable,
    Template,
    TemplateMeta,
    TemplateMetaInfo,
} from '../types';
import ErrorPane, {ErrorMessage} from './ErrorPane';
import TemplateInputForm, { TemplateInputFormProps } from './forms/TemplateInputForm';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import LiveWorkflowInputForm, { LiveWorkflowInputFormProps } from './forms/LiveWorkflowInputForm';
import { WorkflowStatus } from './dag/DagDisplay';
import { useHistory } from 'react-router-dom';
import {parseError} from "../common/utils";
import {getAllFeatureFlagsFromTemplateMeta} from "./utils";
import DEPInputForm, {DEPInputFormProps} from "./forms/DEPInputForm";

interface RequestInputFormProps {
    generateNewDag: (requestSelectionState: RequestSelectionState) => void;
    generateDagByWorkflowId: (workflowId: string) => void;
    generateStructuralDag: (provisionableType: string | undefined, provisionableVersion: string | undefined) => void;
    environmentOverrides: Selectable[];
    requestTypes: Selectable[];
    featureFlags: any;
    templateMeta: TemplateMeta | null;
    workflowStatus: WorkflowStatus;
    featureFlagOptions: ObjectSelectable[];
    provisionableTypes: Selectable[];
    provisionableVersions: Selectable[];
    diffsInDag?: any;
 }

// TODO: Consider converting into functional component w/ Hooks for state
const RequestInputForm: FC<RequestInputFormProps> = (
    {
        generateNewDag,
        generateDagByWorkflowId,
        generateStructuralDag,
        environmentOverrides,
        requestTypes,
        featureFlags,
        templateMeta,
        workflowStatus,
        featureFlagOptions,
        provisionableTypes,
        provisionableVersions,
        diffsInDag,
     }) => {
    const params = new URLSearchParams(window.location.search)
    const history = useHistory();

    const [tab, setTab] = useState<number>(Number(params.get('tab')) || 0);
    const [inputsForRequest, setInputsForRequest] = useState<Selectable[]>([]);
    const [statesForRequest, setStatesForRequest] = useState<Selectable[]>([]);
    const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
    const [loading, setLoading] = useState(false);
    const [requestSelectionState, setRequestSelectionState] = useState<RequestSelectionState>({
        selectedRequestType: null,
        selectedInputTemplate: null,
        selectedStateTemplate: null,
        entityId: 'test-entity-id',
        microsEnvironment: null,
        state: {},
        input: {},
        featureFlags: {},
        featureFlagOption: [],
        featureFlagSelection: null,
        selectedProvisionableType:null,
        selectedProvisionableVersion:null,
    });


    const handleError = (errorObject: any) => {
        if (
            errorObject === undefined ||
            errorObject === null ||
            Object.keys(errorObject).length === 0
        ) {
            return;
        }
        if (errorMessages.length <= 5) {
            setLoading(false);
            setErrorMessages(errorMessages.concat(parseError(errorObject)));
        } else {
            console.log(errorObject);
        }
        if (errorMessages.length === 5) {
            setLoading(false);
            setErrorMessages(errorMessages.concat({ message: 'Message limit exceeded. Further error messages ' + 'will be logged to console'}));
        }
    };


    React.useEffect(() => {
        setRequestSelectionState({
            ...requestSelectionState,
            microsEnvironment: environmentOverrides[0],
            featureFlags: featureFlags,
        });
    }, [featureFlags]);

    React.useEffect(() => {
        const combinedFeatureFlags = getAllFeatureFlagsFromTemplateMeta(templateMeta);
        setRequestSelectionState({
            ...requestSelectionState,
            featureFlagOption:
                Object.keys(combinedFeatureFlags).length === 0
                    ? []
                    : Object.entries(combinedFeatureFlags).map(
                          (entry) =>
                              ({
                                  label: entry[0],
                                  value: entry[1],
                              } as ObjectSelectable),
                      ),
        });
    }, [templateMeta]);

    const getTemplateMetaInfoForRequestType = (requestType: string): TemplateMetaInfo => {
        return [
            templateMeta?.CLOUD,
            templateMeta?.ORGANIZATION,
            templateMeta?.['ati:cloud:trello:enterprise'],
        ].find(
            (templateMetaInfo) => templateMetaInfo && templateMetaInfo.templates[requestType],
        ) as TemplateMetaInfo;
    };

    const getInputTemplates = (requestType: string): string[] => {
        const validInputs = getTemplateMetaInfoForRequestType(requestType)
            .templates[requestType]!.flatMap((ele: Template) => ele.validInputs)
            // Remove duplicate items.
            .filter((value: string, key: number, arr: string[]) => arr.indexOf(value) === key);
        return validInputs ? validInputs : [];
    };

    const getStateTemplates = (requestType: string): string[] => {
        const validStates = getTemplateMetaInfoForRequestType(requestType)
            .templates[requestType]!.flatMap((ele: Template) => ele.validStates)
            .filter((value: string, key: number, arr: string[]) => arr.indexOf(value) === key);
        return validStates ? validStates : [];
    };

    const updateRequestType = (selectable: Selectable): void => {
        setRequestSelectionState({
            ...requestSelectionState,
            selectedRequestType: selectable,
        });
        setInputsForRequest(buildOptionsFromTemplates(
            getInputTemplates(selectable.value),
        ));

        setStatesForRequest(buildOptionsFromTemplates(
            getStateTemplates(selectable.value),
        ));

        clearErrorMessages();
    };

    const updateInputTemplate = (selectable: Selectable) => {
        setRequestSelectionState({
            ...requestSelectionState,
            selectedInputTemplate: selectable,
            input: getTemplateMetaInfoForRequestType(
                requestSelectionState!.selectedRequestType!.value,
            ).inputs[selectable.value]!,
        });
    };

    const updateStateTemplate = (selectable: Selectable) => {
        setRequestSelectionState({
            ...requestSelectionState,
            selectedStateTemplate: selectable,
            state: getTemplateMetaInfoForRequestType(
                requestSelectionState!.selectedRequestType!.value,
            ).states[selectable.value]!,
        });
    };

    const requestInputEdit = (input: any) => {
        setRequestSelectionState({ ...requestSelectionState, input: input.jsObject });
    };

    const requestStateEdit = (state: any) => {
        setRequestSelectionState({ ...requestSelectionState, state: state.jsObject });
    };

    const requestFeatureFlagsEdit = (featureFlags: any) => {
        setRequestSelectionState({
            ...requestSelectionState,
            featureFlags: featureFlags.jsObject,
        });
    };

    const entityIdEdit = (entityId: string) => {
        setRequestSelectionState({
            ...requestSelectionState,
            entityId: entityId,
        });
    };

    const updateMicrosEnvironment = (microsEnvironment: Selectable) => {
        setRequestSelectionState({
            ...requestSelectionState,
            microsEnvironment: microsEnvironment,
        });
    };

    const updateFeatureFlagOnSelect = (selectedFeatureFlagSetting: ObjectSelectable) => {
        const newFeatureFlags = JSON.parse(JSON.stringify(featureFlags));
        Object.keys(selectedFeatureFlagSetting.value).forEach((key) => {
            newFeatureFlags['booleanFlags'][key] = selectedFeatureFlagSetting.value[key];
        });
        setRequestSelectionState({
            ...requestSelectionState,
            featureFlags: newFeatureFlags,
            featureFlagSelection: selectedFeatureFlagSetting,
        });
    };

    const submit = () => {
        if (requestSelectionState.selectedRequestType == null) {
            handleError('Please select request type');
            return;
        }
        generateNewDag(requestSelectionState);
    };


    const clearErrorMessage = (indexToClear: number) => {
        setErrorMessages(errorMessages.filter(
            (_, errorIndex) => errorIndex !== indexToClear,
        ));
    };

    const clearErrorMessages = () => {
        setErrorMessages([]);
    };

    const buildOptionsFromTemplates = (values: string[]): Selectable[] => {
        return values.map((value) => {
            return { label: value, value: value };
        });
    };

    const onTabChange = (index: number) => {
        setTab(index);
        params.set('tab', String(index));
        history.push({ search: params.toString() });
        clearErrorMessages();
    };

    const templateInputFromProps: TemplateInputFormProps = {
        requestType: {
            options: requestTypes,
            value: requestSelectionState.selectedRequestType,
            onSelectChange: (val: any) => updateRequestType(val),
        },
        input: {
            options: inputsForRequest,
            value: requestSelectionState.selectedInputTemplate,
            onSelectChange: (val: any) => updateInputTemplate(val),
            placeholder: requestSelectionState.input,
            onInputChange: requestInputEdit,
        },
        state: {
            options: statesForRequest,
            value: requestSelectionState.selectedStateTemplate,
            onSelectChange: (val: any) => updateStateTemplate(val),
            placeholder: requestSelectionState.state,
            onInputChange: requestStateEdit,
        },
        featureFlags: {
            placeholder: requestSelectionState.featureFlags,
            onInputChange: requestFeatureFlagsEdit,
            options: featureFlagOptions,
            value: requestSelectionState.featureFlagSelection,
            onSelectChange: (val: any) => updateFeatureFlagOnSelect(val),
        },
        entityId: {
            value: requestSelectionState.entityId,
            onChange: (e) => entityIdEdit(e.currentTarget.value),
        },
        microsEnvironment: {
            options: environmentOverrides,
            value: requestSelectionState.microsEnvironment,
            onSelectChange: (val: any) => updateMicrosEnvironment(val),
        },
        onSubmit: submit,
    };

    const liveWorkflowFormProps: LiveWorkflowInputFormProps = {
        workflowStatus,
        generateDagByWorkflowId,
        handleError,
        setErrorMessages,
    };

    const updateProvisionableType = (selectable: Selectable): void => {
        setRequestSelectionState({
            ...requestSelectionState,
            selectedProvisionableType: selectable,
        });

        clearErrorMessages();
    };

    const updateProvisionableVersion = (selectable: Selectable): void => {
        setRequestSelectionState({
            ...requestSelectionState,
            selectedProvisionableVersion: selectable,
        });

        clearErrorMessages();
    };

    const depInputFormProps: DEPInputFormProps = {
        generateStructuralDag,
        provisionableType: {
            options: provisionableTypes,
            value: requestSelectionState.selectedProvisionableType,
            onSelectChange: (val: any) => updateProvisionableType(val),
        },
        provisionableVersion: {
            options: provisionableVersions,
            value: requestSelectionState.selectedProvisionableVersion,
            onSelectChange: (val: any) => updateProvisionableVersion(val),
        },
        diffsInDag: diffsInDag,
        onSubmit: submit,
    };

    // Used for Dynamic Entities Dag Viewer which we only want to show locally for now
    const islocalEnv = (): boolean => {
        return  window.location.href.includes('localhost');
    }

    return (
        <div>
            <ErrorPane
                errorMessages={errorMessages}
                onDismissClick={clearErrorMessages}
            />
            <Tabs
                id="workflowTabs"
                testId="workflowTabs"
                defaultSelected={tab || 0}
                onChange={(index) => onTabChange(index)}
            >
                <TabList>
                    <Tab>Template</Tab>
                    <Tab>Live Workflow</Tab>
                    { islocalEnv() && (
                        <Tab>DEP Dag Viewer</Tab>
                    )}
                </TabList>
                <TabPanel>
                    <TemplateInputForm {...templateInputFromProps} />
                </TabPanel>
                <TabPanel>
                    <LiveWorkflowInputForm {...liveWorkflowFormProps} />
                </TabPanel>
                <TabPanel>
                    <DEPInputForm {...depInputFormProps} />
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default RequestInputForm;
