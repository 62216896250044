import React, { Component } from 'react';
import SectionMessage, { SectionMessageAction } from "@atlaskit/section-message";
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { CodeBlock } from '@atlaskit/code';

interface Props {
    title?: string;
    errorMessages: ErrorMessage[];
    onErrorPaneClick?: (indexToClear: number) => void;
    onDismissClick?: () => void;
}

export interface ErrorPaneState {
    modalOpen: boolean;
    error: ErrorMessage | undefined;
}

export type ErrorMessage = {
    message: string;
    requestUrl?: string;
    statusText?: string;
    details?: string;
}

class ErrorPane extends Component<Props, ErrorPaneState> {
    constructor(props: Props, state: ErrorPaneState) {
        super(props, state);
        this.state = {modalOpen: false, error: undefined};
        this.setModalData = this.setModalData.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    setModalData(message: ErrorMessage) {
        this.setState({modalOpen: true, error: message})
    }

    closeModal() {
        this.setState({modalOpen: false, error: undefined})
    }

    render() {
        return (
            <div>
                {
                    this.props.errorMessages.length > 0 &&
                    <div>
                        <br/>
                        <SectionMessage
                            {... this.props.title ? {title: this.props.title} : {}}
                            appearance="error"
                            actions={[
                                <SectionMessageAction onClick={this.props.onDismissClick}>Dismiss</SectionMessageAction>
                            ]}
                        >
                            {this.props.errorMessages.map((message, i) => (
                                <div>
                                    <p key={i}> {message.message}
                                        {
                                            (message.details || message.statusText) &&
                                            <span>&nbsp;<a href="#" key={'link-'+i} onClick={() => this.setModalData(message)}>see more</a></span>
                                        }
                                    </p>
                                </div>
                            ))}
                        </SectionMessage>
                        <br/>
                    </div>
                }
                {
                    <ModalTransition >
                        {this.state.modalOpen && (
                            <Modal { ...this.state.error?.details ? {width : 'large'} : { width: 'small'}}>
                                <ModalHeader>
                                    <ModalTitle appearance="danger">Something went wrong</ModalTitle>
                                </ModalHeader>
                                <ModalBody>
                                    {
                                        this.state.error?.requestUrl &&
                                        <div>
                                            <span className='error-sub-title'>Request URL</span>
                                            <CodeBlock showLineNumbers={true} text={this.state.error?.requestUrl as string}/>
                                        </div>
                                    }
                                    {
                                        this.state.error?.message &&
                                        <div>
                                            <br/>
                                            <p className='error-sub-title'>Message</p>
                                            <span>{this.state.error?.message as string}</span>
                                        </div>
                                    }
                                    {
                                        this.state.error?.details &&
                                        <div>
                                            <br/>
                                            <p className='error-sub-title'>Details:</p>
                                            <CodeBlock showLineNumbers={true} text={this.state.error?.details}/>
                                        </div>
                                    }
                                    {
                                        this.state.error?.statusText &&
                                        <div>
                                            <br/>
                                            <p className='error-sub-title'>Details:</p>
                                            <span>{this.state.error?.statusText}</span>
                                        </div>
                                    }
                                </ModalBody>
                                <ModalFooter>
                                    <Button appearance="primary" onClick={this.closeModal}>Close</Button>
                                </ModalFooter>
                            </Modal>
                        )}
                    </ModalTransition>
                }
            </div>
        );

        /*return (
            <div className={'full-width'}>
                {this.props.errorMessages.map((message, i) => (
                    <div
                        key={i}
                        className={'pane error'}
                        onClick={() => this.props.onErrorPaneClick(i)}
                    >
                        {message}
                    </div>
                ))}
            </div>
        );*/
    }
}

export default ErrorPane;
