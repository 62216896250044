import styled from '@emotion/styled';
import { DN90, N30 } from '@atlaskit/theme/colors';
import { gridSize } from '@atlaskit/theme/constants';

export const Label = styled.label`
    color: ${DN90};
    font-size: 12px;
    font-weight: 600;
    padding: ${gridSize()}px 0;
    line-height: ${gridSize() * 2}px;
`;

export const Divider = styled.div`
    border-top: 2px solid ${N30};
    border-radius: 2px;
    margin: ${gridSize()}px 2px ${gridSize() / 2}px 2px;
`;

export const PaddedDiv = styled.div`
    padding: 10px;
`;