import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import Button, { ButtonGroup } from "@atlaskit/button";
import { WorkflowStatus } from "./forms/LiveWorkflowInputForm";
import Select from "@atlaskit/select";
import { Selectable } from "../types";
import styled from "@emotion/styled";
import { DN90 } from "@atlaskit/theme/colors";
import Tooltip from '@atlaskit/tooltip';

interface WorkflowTableProps {
    workflowStatusList: WorkflowStatus[];
    onWorkflowSelect: (workflowId: string) => void;
}

interface Header {
    key: string;
    content: string;
    isSortable: boolean;
}

const headers: Header[] = [
    { key: 'workflowId', content: 'Workflow ID', isSortable: false },
    { key: 'tags', content: 'Tags', isSortable: false },
    { key: 'status', content: 'Status', isSortable: true },
    { key: 'started', content: 'Start Time', isSortable: true },
    { key: 'closed', content: 'End Time', isSortable: true },
]

const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const RowPageContainer = styled.div`
    display: flex;
`

const Label = styled.label`
    color: ${DN90};
    font-size: 16px;
    font-weight: 600;
`

const WorkflowTable: FC<WorkflowTableProps> = (
    {
        workflowStatusList,
        onWorkflowSelect,
    }) => {
    const workflowId = useRef<string>();
    const highlightedIndex = useRef<number>();

    const RowsPerPageOptions = useMemo(() => {
        return [
            { label: '5', value: '5' },
            { label: '10', value: '10' },
            { label: '20', value: '20' }
        ];
    }, []);

    const covertDataToRows = useCallback((workflowStatusList: WorkflowStatus[]) => {
        return workflowStatusList.map((status) => ({
            key: status.workflowId,
            cells: [
                {
                    key: headers[0].key,
                    content: status.workflowId,
                },
                {
                    key: headers[1].key,
                    content: status.tags?.join('\n'),
                },
                {
                    key: headers[2].key,
                    content: status.status,
                },
                {
                    key: headers[3].key,
                    content: status.started,
                },
                {
                    key: headers[4].key,
                    content: status.closed,
                },
            ],
            onClick: () => {
                if (workflowId.current === status.workflowId) {
                    return;
                }
                workflowId.current = status.workflowId;
                onWorkflowSelect(status.workflowId);
                highlightRow(rowsRef.current);
            },
        }))
    }, []);

    const highlightRow = (rows: any[]) => {
        const rowsWithHighlightedRow = [...rows];
        //reset previously highlighted row
        if (highlightedIndex.current !== undefined) {
            rowsWithHighlightedRow[highlightedIndex.current].isHighlighted = false;
        }

        const index = rowsWithHighlightedRow.findIndex(row => row.key === workflowId.current);
        if (index > -1) {
            rowsWithHighlightedRow[index].isHighlighted = true;
            highlightedIndex.current = index;
        }
    };

    const initRows = covertDataToRows(workflowStatusList.sort((a, b) => b.started.localeCompare(a.started)));
    const rowsRef = useRef<any[]>(initRows);
    const [pageNumber, setPageNumber] = useState(1);
    const [rows, setRows] = useState<any>(initRows);
    const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('DESC');
    const [sortKey, setSortKey] = useState('started');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigateTo = (pageNumber: number) => {
        setPageNumber(pageNumber);
    };

    const head = {
        cells: headers,
    }

    const onSort = (data: any) => {
        const { key, sortOrder } = data;
        setSortOrder(sortOrder);
        setSortKey(key);
        let newRows: any[];
        if (sortOrder === 'DESC') {
            workflowStatusList.sort((a, b) => b[key]?.localeCompare(a[key]));
            newRows = covertDataToRows(workflowStatusList)
        } else {
            workflowStatusList.sort((a, b) => a[key]?.localeCompare(b[key]));
            newRows = covertDataToRows(workflowStatusList)
        }
        setRows(newRows);
        rowsRef.current = newRows;
    };

    const onRowsPerPageSelect = (e: Selectable | null) => {
        if (!e) {
            return;
        }
        setRowsPerPage(Number(e.value));
    };
    return (
        <>
            {workflowStatusList.length >= rowsPerPage &&
                <ActionContainer>
                    <ButtonGroup>
                        <Button
                            isDisabled={pageNumber === 1}
                            onClick={() => navigateTo(pageNumber - 1)}
                        >
                            Previous Page
                        </Button>
                        <Button
                            isDisabled={pageNumber === Math.round(workflowStatusList.length / rowsPerPage) + 1}
                            onClick={() => navigateTo(pageNumber + 1)}
                        >
                            Next Page
                        </Button>

                    </ButtonGroup>
                    <RowPageContainer>
                        <Tooltip content="Rows Per Page" position="right">
                            <Select
                                inputId="rowsPerPage"
                                options={RowsPerPageOptions}
                                value={{ label: String(rowsPerPage), value: String(rowsPerPage) }}
                                onChange={(e) => onRowsPerPageSelect(e)}
                                styles={{
                                    control: (base) => ({ ...base, width: '64px', height: '32px' })
                                }}
                            />
                        </Tooltip>
                    </RowPageContainer>
                </ActionContainer>}

            <DynamicTableStateless
                head={head}
                rows={rows}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                loadingSpinnerSize="large"
                isLoading={false}
                isFixedSize
                sortKey={sortKey}
                sortOrder={sortOrder}
                onSort={onSort}
                onSetPage={(pageNumber) => setPageNumber(pageNumber)}
            />
        </>);

};

export default WorkflowTable;