import axios, {AxiosError} from 'axios';
import { Selectable, TemplateMeta } from '../types';
import { RequestItem } from '../components/dag/Types';
import {ErrorMessage} from "../components/ErrorPane";

export function fetchSelectableRequestTypes(): Promise<Selectable[]> {
    return axios.get('/dependency-ui/types').then((response) => {
        return response.data.map((item: Set<string>) => {
            return {
                value: item,
                label: item,
            };
        });
    });
}

export function fetchTemplates(): Promise<TemplateMeta> {
    return axios.get('/dependency-ui/templates').then((response) => {
        return response.data;
    });
}

export function fetchFeatureFlags(): Promise<any> {
    return axios.get('/dependency-ui/featureFlags').then((response) => {
        return response.data;
    });
}

export function fetchDagByWorkFlowId(workflowId: string): Promise<any> {
    return axios.get(`/dependency-ui/buildFrom/${workflowId}`).then((response) => {
        return response.data;
    });
}

export function fetchWorkflowStatus(workflowId: string): Promise<any> {
    return axios.get(`/status/workflow/${workflowId}`).then((response) => {
        return [response.data];
    });
}

export function fetchWorkflowStatusByTag(tag: string): Promise<any> {
    return axios.get(`/status/tag/${tag}`).then((response) => {
        return response.data;
    });
}

export function fetchWorkflowStatusByHostname(hostname: string): Promise<any> {
    return axios.get(`/status/hostname/${hostname}`).then((response) => {
        return response.data;
    });
}

export function fetchDag(
    requestName: string,
    state: any,
    input: any,
    featureFlags: any,
    entityId: string,
    microsEnvironment: string,
): Promise<RequestItem[]> {
    return axios
        .post('/dependency-ui/build', {
            requestName,
            state,
            input,
            featureFlags,
            entityId,
            microsEnvironment,
        })
        .then((response) => {
            // if the UI returns an empty set of activities, it will crash the UI
            return response.data.length === 0 ? [{ id: 'EMPTY', parentIds: [] }] : response.data;
        });
}

export function fetchWorkFlowStatus(workflowId: string): Promise<any> {
    return axios.get(`/status/workflow/${workflowId}`).then((response) => {
        return response.data;
    });
}

export function fetchProvisionableTypes(): Promise<Selectable[]> {
     const mockResponse = [
            {
                "value": "Confluence",
                "label": "Confluence"
            },
            {
                "value": "Bitbucket",
                "label": "Bitbucket"
            },
            {
                "value": "Atlas",
                "label": "Atlas"
            }
        ];

        return Promise.resolve(mockResponse);
}

export function fetchProvisionableVersions(): Promise<Selectable[]> {
    const mockVersionResponses = [
        {
            "value": "1.0",
            "label": "1.0"
        },
        {
            "value": "2.0",
            "label": "2.0"
        },
        {
            "value": "3.0",
            "label": "3.0"
        }
    ];

    return Promise.resolve(mockVersionResponses);
}

export const STATUS_COLOR_MAPPING = {
    completed: {
        label: 'COMPLETED',
        color: 'rgb(2,171,2)',
    },
    partiallyCompleted:{
        label:'PARTIALLY COMPLETED',
        color: 'rgb(242,147,57)',
    },
    inflight: {
        label: 'IN-FLIGHT',
        color: 'rgb(20, 110, 219)',
    },
    pending: {
        label: 'PENDING',
        color: 'rgb(112,128,144)',
    },
    errors: {
        label: 'ERROR',
        color: 'rgb(255,0,0)',
    },
};

export function isGroupStartNode(dagItem: RequestItem) {
    return dagItem.id.startsWith("START[")
}

export function isCollapsedGroupNode(dagItem: RequestItem) {
    return dagItem.id.startsWith("GROUP[")
}

export function isGroupEndNode(dagItem: RequestItem) {
    return dagItem.id.startsWith("END[")
}

export function isActivityNode(dagItem: RequestItem) {    
    return dagItem.groupId != undefined
}

export function extractGroupName(dagItem: RequestItem): string {
    const groupLabelPattern = /.*\[(.*)\]/
    return dagItem.id.match(groupLabelPattern)![1]
}

export function isGroupMember(dagItem: RequestItem, groupId: string) {
    return dagItem.groupId === groupId;
}

export function isGroupStart(dagItem: RequestItem, groupId: string) {
    return dagItem.id === "START[" + groupId + "]";
}

export function isGroupEnd(dagItem: RequestItem, groupId: string) {
    return dagItem.id === "END[" + groupId + "]";
}

export function computeNewDag(dagItems: RequestItem[], collapsedGroupIds: string[]): RequestItem[] {    
    let newDag: RequestItem[] = [...dagItems];
    collapsedGroupIds.forEach(function(groupId) {
        newDag = removeCollapsedNodes(newDag, groupId)
    })
    return newDag;
}

export function removeCollapsedNodes(dagItems: RequestItem[], collapsedGroupId: string): RequestItem[] {
    return dagItems
        .filter((item) => !isGroupMember(item, collapsedGroupId))
        .filter((item) => !isGroupEnd(item, collapsedGroupId))
        .map((item) => replaceCollapsedGroupStartWithGroupLabel(item, collapsedGroupId))
        .map((item => reparentIfChildOfCollapsedGroup(item, collapsedGroupId, dagItems)));
}

export function reparentIfChildOfCollapsedGroup(dagItem: RequestItem, collapsedGroupId: string, dagItems: RequestItem[]): RequestItem {
    const newParents: Set<string> = new Set();
    const collapsedGroupStartNode: RequestItem = getGroupStartNode(dagItems, collapsedGroupId)
    dagItem.parentIds.forEach(parentId => {
        const parent = getNode(dagItems, parentId)
        if (isGroupMember(parent, collapsedGroupId) || isGroupEnd(parent, collapsedGroupId)) {
            newParents.add(getCollapsedGroupLabel(collapsedGroupId))
        } else {
            newParents.add(parentId);
        }}
    )
    return {id: dagItem.id, groupId: dagItem.groupId, parentIds: Array.from(newParents)}
}

export function replaceCollapsedGroupStartWithGroupLabel(dagItem: RequestItem, collapsedGroupId: string): RequestItem {
    if (isGroupStart(dagItem, collapsedGroupId)) {
        return {
            id: getCollapsedGroupLabel(extractGroupName(dagItem)),
            groupId: null,
            parentIds: dagItem.parentIds
        }
    }
    return dagItem
}

export function getCollapsedGroupLabel(groupName: string) {
    return "GROUP[" + groupName + "]"
}

export function getNode(dagItems: RequestItem[], id: string): RequestItem {
    return dagItems.filter(item => item.id === id)[0]
}

export function getGroupStartNode(dagItems: RequestItem[], groupId: string): RequestItem {
    return dagItems.filter(item => isGroupStart(item, groupId))[0]
}

export function getAllGroups(dagItems: RequestItem[]): Set<string> {
    return new Set(
        dagItems
            .filter(item => item.groupId != undefined)
            .map(item => item.groupId)
        )
}

export function hasStartNode(dagItems: RequestItem[]) : boolean {
    return dagItems.some(item => item.id === "workflow-start")
}

export function parseAxiosError(axiosError: AxiosError) {
    let request = axiosError.request as XMLHttpRequest;
    let error = {} as ErrorMessage;
    error.message = axiosError.message;

    if (request.responseURL) {
        error.requestUrl = request.responseURL;
    }
    if (axiosError.response?.data?.error) {
        error.details = axiosError.response?.data?.error;
    } else if (request.statusText){
        error.statusText = request.statusText;
    }
    return error;
}

export function parseError(error: any) {
    let errorMessage: ErrorMessage;
    const axiosError = error as AxiosError;
    if (axiosError.message && axiosError.response) {
        errorMessage = parseAxiosError(axiosError);
    }
    else {
        errorMessage = { message: String(error) };
    }
    return errorMessage;
}