import Textfield from '@atlaskit/textfield';
import React, { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { Divider, Label } from './Forms.styles';
import Button, { LoadingButton } from '@atlaskit/button';
import { WorkflowStatus } from '../dag/DagDisplay';
import styled from '@emotion/styled';
import Select from "@atlaskit/select";
import { Selectable } from "../../types";
import { useHistory } from "react-router-dom";
import WorkflowTable from "../WorkflowTable";
import {
    fetchSelectableRequestTypes,
    fetchWorkflowStatus,
    fetchWorkflowStatusByHostname,
    fetchWorkflowStatusByTag, parseError
} from "../../common/utils";
import {ErrorMessage} from "../ErrorPane";
import {AxiosError} from "axios";

const Container = styled.div`
    width: 100%;
    padding: 12px 0;
`

const FormContainer = styled.div`
    margin-bottom: 16px;
`

export interface LiveWorkflowInputFormProps {
    workflowStatus: WorkflowStatus;
    generateDagByWorkflowId: (workflowId: string) => void;
    handleError: (errorObject: any) => void;
    setErrorMessages: Dispatch<SetStateAction<ErrorMessage[]>>
}

interface Filter extends Selectable {
    description?: string;
}


const filters: Filter[] = [
    { value: 'workflowId', label: 'Workflow ID' },
    { value: 'entityId', label: 'Entity ID' },
    { value: 'requestType', label: 'Request Type' },
    { value: 'hostname', label: 'Hostname' }
];

const additionalFilter = { value: 'requestType', label: 'Request Type' };

export interface WorkflowStatus {
    workflowId: string;
    tags: string[];
    status: string;
    started: string;
    closed: string;
}

interface FilterElement {
    id: string;
    label: string;
    value: string;
    onChange: (e: FormEvent<HTMLInputElement>) => void;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const LiveWorkflowInputForm: FC<LiveWorkflowInputFormProps> = (
    {
        generateDagByWorkflowId,
        handleError,
        setErrorMessages,
    }) => {
    const params = new URLSearchParams(window.location.search)
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState<Filter>(filters.find(filter => filter.value === params.get('filter')) || filters[0]);
    const [expandAdditionalFilter, setExpandAdditionalFilter] = useState(false);
    const [workflowId, setWorkflowId] = useState<string>(params.get('workflowId') || '');
    const [entityId, setEntityId] = useState<string>(params.get('entityId') || '');
    const [requestType, setRequestType] = useState<string>(params.get('requestType') || '');
    const [additionalRequestType, setAdditionalRequestType] = useState<string>('');
    const [hostname, setHostname] = useState<string>(params.get('hostname') || '');
    const [workflowStatusList, setWorkflowStatusList] = useState<WorkflowStatus[]>([]);
    const history = useHistory();
    const [requestTypes, setRequestTypes] = useState<Selectable[]>([]);

    useEffect(() => {
        if (filter.value === 'workflowId' && workflowId) {
            setLoading(true);
            fetchWorkflowStatus(workflowId).then((res) => {
                setWorkflowStatusList(res);
                setLoading(false);
                generateDagByWorkflowId(workflowId);
            }).catch((e: AxiosError) => {
                setErrorMessages([{ message: e.message, details: e.response?.data?.error }]);
                setLoading(false);
            });
        }
        fetchSelectableRequestTypes().then((data: Selectable[]) => {
            setRequestTypes(data);
        });
    }, []);

    const restState = () => {
        setWorkflowStatusList([]);
        setErrorMessages([]);
        setLoading(true);
    };

    const onFilterChange = (filter: Selectable) => {
        setFilter(filter);
        setAdditionalRequestType('');
        params.set('filter', filter.value);
        history.push({ search: params.toString() });
    };

    const onFormSubmit = (e: FormEvent<HTMLFormElement>, value: string, fetchFn: (input: string) => Promise<any>) => {
        e.preventDefault();
        restState();
        if (!value) {
            setLoading(false);
            setErrorMessages([ { message: `${filter.label} is not provided` }]);
            return;
        }
        params.set(filter.value, value);
        history.push({ search: params.toString() });
        fetchFn(value).then().then((res) => {
            if (additionalRequestType) {
                const filteredList = res.filter((e: WorkflowStatus) => {
                    return e.tags.findIndex(tag => tag === additionalRequestType) !== -1;
                });
                setWorkflowStatusList(filteredList);
            } else {
                setWorkflowStatusList(res);
            }
            setLoading(false);
            if (filter.value === 'workflowId') {
                generateDagByWorkflowId(workflowId);
            }

        }).catch((errorObject) => {
            setLoading(false);
            setErrorMessages([parseError(errorObject)]);
        });

    }

    const onRequestTypeSelect = (e: any) => {
        setRequestType(e.value);
    }

    const onWfIdSubmit = (e: FormEvent<HTMLFormElement>) => {
        onFormSubmit(e, workflowId, fetchWorkflowStatus);
    };

    const onEntityIdSubmit = (e: FormEvent<HTMLFormElement>) => {
        onFormSubmit(e, encodeURIComponent(entityId), fetchWorkflowStatusByTag);
    };

    const onRequestTypeSubmit = (e: FormEvent<HTMLFormElement>) => {
        onFormSubmit(e, requestType, fetchWorkflowStatusByTag);
    };

    const onHostnameSubmit = (e: FormEvent<HTMLFormElement>) => {
        onFormSubmit(e, hostname, fetchWorkflowStatusByHostname);
    };

    const onWorkflowSelect = (workflowId: string) => {
        generateDagByWorkflowId(workflowId);
    };

    const filterElements: FilterElement[] = [
        {
            id: "workflowId",
            label: "Workflow ID",
            value: workflowId,
            onChange: (e: any) => setWorkflowId(e.target.value),
            onSubmit: onWfIdSubmit,
        },
        {
            id: "entityId",
            label: "Entity ID",
            value: entityId,
            onChange: (e: any) => setEntityId(e.target.value),
            onSubmit: onEntityIdSubmit,
        },
        {
            id: "requestType",
            label: "Request Type",
            value: requestType,
            onChange: (e: any) => setRequestType(e.target.value),
            onSubmit: onRequestTypeSubmit,
        },
        {
            id: "hostname",
            label: "Hostname",
            value: hostname,
            onChange: (e: any) => setHostname(e.target.value),
            onSubmit: onHostnameSubmit,
        }
    ];

    const filterElement = filterElements.find(e => e.id === filter.value);

    return (
        <Container>
            <Label htmlFor="filter-by">Filter by</Label>
            <Select
                inputId="filter-by"
                options={filters}
                value={filter}
                onChange={(e: any) => onFilterChange(e)}
            />

            {filterElement &&
                <FormContainer>
                    <form onSubmit={filterElement.onSubmit}>
                        <div className={'input'}>
                            <Label htmlFor={filterElement.id}>{filterElement.label}</Label>
                            {filterElement.id === 'requestType' &&
                                <Select
                                    inputId={filterElement.id}
                                    options={requestTypes}
                                    placeholder="Choose a Request Type"
                                    value={{ label: requestType, value: requestType }}
                                    onChange={onRequestTypeSelect}
                                />}
                            {filterElement.id !== 'requestType' && <Textfield
                                testId={filterElement.id}
                                value={filterElement.value}
                                onChange={filterElement.onChange}
                            />}
                        </div>
                        {!['workflowId', 'requestType'].includes(filter.value) && <>
                            <Button appearance="subtle"
                                    onClick={() => setExpandAdditionalFilter(!expandAdditionalFilter)}>
                                {expandAdditionalFilter ? '∨' : '>'} Additional Filter
                            </Button>
                            {expandAdditionalFilter && <>
                                <div className={'input'}>
                                    <Label htmlFor='additionalRequestType'>Request Type</Label>
                                    <Select
                                        inputId='additionalRequestType'
                                        options={requestTypes}
                                        placeholder="Choose a Request Type"
                                        value={{ label: additionalRequestType, value: additionalRequestType }}
                                        onChange={(e: any) => setAdditionalRequestType(e.value)}
                                    />
                                </div>
                            </>}
                        </>}


                        <div className={'container'}>
                            <LoadingButton appearance="primary" type="submit" isLoading={loading}>
                                Submit
                            </LoadingButton>
                        </div>
                    </form>
                </FormContainer>
            }
            {workflowStatusList && workflowStatusList.length > 0 &&
                <>
                    <Divider/>
                    <WorkflowTable workflowStatusList={workflowStatusList} onWorkflowSelect={onWorkflowSelect}/>
                </>
            }
        </Container>
    );
}

export default LiveWorkflowInputForm;