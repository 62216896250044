import React, { Component } from 'react';
import { environmentOverrides, environments } from './constants';
import WorkflowInspector from './components/WorkflowInspector';
import NavigationHeader from './components/navigation/NavigationHeader';
import './style/style.css';
import { PageLayout, TopNavigation } from '@atlaskit/page-layout';

class App extends Component {
    render() {
        const dag = new URLSearchParams(window.location.search).get('dag');
        const request = new URLSearchParams(window.location.search).get('request');
        const providedDag =
            dag && request ? { dag: JSON.parse(dag), request: JSON.parse(request) } : undefined;
        const workflowGeneratedUrlEnvironment = environments.find((x) => x.label === 'Staging');

        return (
            <PageLayout>
                {
                    <TopNavigation>
                        <NavigationHeader />
                    </TopNavigation>
                }
                <WorkflowInspector
                    environmentBaseUrl={workflowGeneratedUrlEnvironment!.value}
                    providedDag={providedDag}
                    environmentOverrides={environmentOverrides}
                />
            </PageLayout>
        );
    }
}

export default App;
