import { Selectable } from './types';

const isFedramp = window.location.href.match(/atl-paas-us-gov-mod.net/);

const commercialEnvironments: Selectable[] = [
    {
        label: 'Production', 
        value: 'https://cloud-provisioner.sgw.prod.atl-paas.net/',
    },
    {
        label: 'Staging',
        value: 'https://cloud-provisioner.us-east-1.staging.atl-paas.net/',
    },
    {
        label: 'App Dev',
        value: 'https://cloud-provisioner--app.ap-southeast-2.dev.atl-paas.net/',
    },
    {
        label: 'Domain Dev',
        value: 'https://cloud-provisioner.ap-southeast-2.dev.atl-paas.net/',
    },
    { label: 'Local', value: 'http://localhost:8080/' },
];

const fedrampEnvironments: Selectable[] = [
    {
        label: 'Production', 
        value: 'https://cloud-provisioner.sgw.prod.atl-paas-us-gov-mod.net/',
    },
    {
        label: 'Staging',
        value: 'https://cloud-provisioner.us-east-1.staging.atl-paas-us-gov-mod.net/',
    }
];

export const environments: Selectable[] = isFedramp ? fedrampEnvironments : commercialEnvironments;

export const environmentOverrides: Selectable[] = [
    { label: 'default', value: '' },
    { label: 'prod-east', value: 'prod-east' },
    { label: 'stg-east', value: 'stg-east' },
    { label: 'adev', value: 'adev' },
    { label: 'ddev', value: 'ddev' },
];
